import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Button, Col, Form, Input, message, Row, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import '../resourses/authentication.css'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Store } from '../redux/Store'
import LoadingBox from '../components/LoadingBox'
import { reducer } from '../redux/Reducer'

function UpdateCategory() {
  const { state } = useContext(Store)
  const { userInfo } = state
  const params = useParams()
  const { id } = params
  const [{ loading, error, loadingUpdate }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [image, setImage] = useState('')

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' })
        const { data } = await axios.get(
          `/api/categories/${id}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          },
          {
            signal: ac.signal,
          }
        )
        setImage(data.image)
        setName(data.name)

        dispatch({ type: 'FETCH_SUCCESS', payload: data })
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: err,
        })
      }
    }
    fetchData()
    return () => ac.abort()
  }, [userInfo, id])

  const onFinish = async () => {
    try {
      dispatch({ type: 'UPDATE_REQUEST' })
      await axios.put(
        `/api/categories/${id}`,
        {
          _id: id,
          name,
          image,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      )
      dispatch({ type: 'UPDATE_SUCCESS' })
      message.success('Catégories modifiée')
      navigate('/')
    } catch (error) {
      dispatch({ type: 'UPDATE_FAIL' })
      message.error('Quelque chose ne va pas', error)
    }
  }

  const props = {
    name: 'file',
    action: '/api/upload',
    headers: {
      authorizaton: `Bearer ${userInfo.token}`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} téléchargée`)
        setImage(info.file.response.secure_url)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  return (
    <div className='authentication'>
      <Row>
        <Col lg={8} xs={22}>
          {loading ? (
            <LoadingBox />
          ) : error ? (
            message.error('Quelque chose ne va pas')
          ) : (
            <>
              <Form layout='vertical' onFinish={onFinish}>
                <h1>
                  <b>MOGES-APP</b>
                </h1>
                <hr />
                <h3>Modifier Catégorie </h3>

                <Form.Item
                  name={['category', 'name']}
                  label='Nom'
                  onChange={(e) => setName(e.target.value)}
                  initialValue={name}
                >
                  <Input />
                </Form.Item>

                <Upload listType='picture' {...props}>
                  <Button icon={<UploadOutlined />}>Télécharger Image</Button>
                </Upload>
                <br />

                {image ? (
                  <Form.Item
                    name={['category', 'image']}
                    label='Image'
                    initialValue={image}
                  >
                    <Input />
                  </Form.Item>
                ) : (
                  ''
                )}

                <div className='d-flex justify-content-between align-items-center'>
                  <Button htmlType='submit' type='primary'>
                    Modifier
                  </Button>
                  {loadingUpdate && <LoadingBox />}
                </div>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default UpdateCategory
