export function reducer(state, action) {
  switch (action.type) {
    case 'CREATE_REQUEST':
      return { ...state, loadingCreate: true, classe: action.payload }
    case 'CREATE_SUCCESS':
      return { ...state, loadingCreate: false }
    case 'CREATE_FAIL':
      return { ...state, loadingCreate: false }
    case 'FETCH_REQUEST':
      return { ...state, loading: true }
    case 'FETCH_SUCCESS':
      return { ...state, loading: false }
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload }
    case 'FETCH_SUCCESS_SUMMARY':
      return {
        ...state,
        summary: action.payload,
        loading: false,
      }

    case 'FETCH_SUCCESS_DETAILS':
      return {
        ...state,
        details: action.payload,
        loading: false,
      }
    case 'FETCH_SUCCESS_TRANSACTION_SUMMARY':
      return {
        ...state,
        transaction_summary: action.payload,
        loading: false,
      }
    case 'FETCH_SUCCESS_YEARLYBILANS':
      return {
        ...state,
        yearlybilans: action.payload,
        loading: false,
      }
    case 'DELETE_REQUEST':
      return { ...state, loadingDelete: true, successDelete: false }
    case 'DELETE_SUCCESS':
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      }
    case 'DELETE_FAIL':
      return { ...state, loadingDelete: false }
    case 'DELETE_RESET':
      return { ...state, loadingDelete: false, successDelete: false }
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true }
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false }
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false }
    case 'FETCH_TRANSACTIONS_SUCCESS':
      return {
        ...state,
        transactions: action.payload.transactions,
        loading: false,
      }

    case 'FETCH_STOCK_TRANSACTIONS_SUCCESS':
      return {
        ...state,
        stockTransactions: action.payload.stockTransactions,
        loading: false,
      }
    case 'FETCH_USERS_SUCCESS':
      return {
        ...state,
        users: action.payload.users,
        page: action.payload.page,
        loading: false,
      }
    default:
      return state
  }
}
