import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Button, Col, Form, Input, message, Row, Checkbox } from 'antd'
import '../resourses/authentication.css'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Store } from '../redux/Store'
import LoadingBox from '../components/LoadingBox'
import { reducer } from '../redux/Reducer'

function UpdateUser() {
  const { state } = useContext(Store)
  const { userInfo } = state
  const params = useParams()
  const { id } = params
  const [{ loading, error, loadingUpdate }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' })
        const { data } = await axios.get(
          `/api/users/${id}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          },
          {
            signal: ac.signal,
          }
        )
        setEmail(data.email)
        setName(data.name)
        setIsAdmin(data.isAdmin)

        dispatch({ type: 'FETCH_SUCCESS', payload: data })
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: err,
        })
      }
    }
    fetchData()
    return () => ac.abort()
  }, [userInfo, id])

  const onFinish = async () => {
    try {
      dispatch({ type: 'UPDATE_REQUEST' })
      await axios.put(
        `/api/users/${id}`,
        {
          _id: id,
          name,
          email,
          isAdmin,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      )
      dispatch({ type: 'UPDATE_SUCCESS' })
      message.success('Updateed successfull , please wait for verification')
      navigate('/admin/users')
    } catch (error) {
      dispatch({ type: 'UPDATE_FAIL' })
      message.error('Quelque chose ne va pas', error)
    }
  }

  return (
    <div className='authentication'>
      <Row>
        <Col lg={8} xs={22}>
          {loading ? (
            <LoadingBox />
          ) : error ? (
            message.error('Quelque chose ne va pas')
          ) : (
            <>
              <Form layout='vertical' onFinish={onFinish}>
                <h1>
                  <b>MOGES-APP</b>
                </h1>
                <hr />
                <h3>Modifier Utilisateur</h3>

                <Form.Item
                  name={['user', 'email']}
                  label='Email'
                  initialValue={email}
                  rules={[
                    {
                      type: 'email',
                    },
                  ]}
                >
                  <Input onChange={(e) => setEmail(e.target.value)} />
                </Form.Item>
                <Form.Item
                  name={['user', 'name']}
                  label='Nom'
                  onChange={(e) => setName(e.target.value)}
                  initialValue={name}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name={['user', 'isAdmin']}
                  checked={isAdmin}
                  valuePropName='checked'
                  initialValue={isAdmin}
                  label='Est admin'
                  onChange={(e) => setIsAdmin(e.target.checked)}
                >
                  <Checkbox></Checkbox>
                </Form.Item>

                <div className='d-flex justify-content-between align-items-center'>
                  <Button htmlType='submit' type='primary'>
                    Valider
                  </Button>
                  {loadingUpdate && <LoadingBox />}
                </div>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default UpdateUser
