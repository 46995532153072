import React, { useContext, useState } from 'react'
import { Badge, Layout, Menu, theme } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  HomeOutlined,
  CopyOutlined,
  ShoppingOutlined,
  UnorderedListOutlined,
  LoginOutlined,
  StockOutlined,
  ShoppingCartOutlined,
  VerticalAlignBottomOutlined,
  FallOutlined,
  TeamOutlined,
  TagsOutlined,
  ShopOutlined,
} from '@ant-design/icons'
import '../resourses/layout.css'
import { Link, useNavigate } from 'react-router-dom'
import { Store } from '../redux/Store'
import { useEffect } from 'react'
const { Header, Sider, Content } = Layout

const DefaultLayout = (props) => {
  const [collapsed, setCollapsed] = useState(true)
  const navigate = useNavigate()
  const toggle = () => {
    setCollapsed(!collapsed)
  }
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return { width, height }
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  /*useEffect(() => {
    if (windowDimensions.width <= 975) {
      return () => setCollapsed((prev) => !prev)
    }
    if (windowDimensions.width > 975) {
      if (collapsed === false) {
        return
      } else {
        setCollapsed((prev) => !prev)
      }
    }
  }, [windowDimensions, collapsed])*/

  const { state, dispatch: ctxDispatch } = useContext(Store)
  const { userInfo, cart } = state
  const logoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' })
    localStorage.removeItem('userInfo')
    window.location.href = '/login'
  }
  const adminItems = [
    {
      key: '/accueil',
      icon: <HomeOutlined />,
      label: <Link to='/'>Accueil</Link>,
    },
    {
      key: '/cart',
      icon: <ShoppingCartOutlined />,
      label: <Link to='/cart'>Panier</Link>,
    },
    {
      key: '/admin/bilans',
      icon: <StockOutlined />,
      label: <Link to='/admin/bilans'>Bilans</Link>,
    },
    {
      key: '/admin/expenses',
      icon: <FallOutlined />,
      label: <Link to='/admin/expenses'>Dépenses</Link>,
    },
    {
      key: '/admin/users',
      icon: <TeamOutlined />,
      label: <Link to='/admin/users'>Utilisateurs</Link>,
    },
    {
      key: '/admin/categories',
      icon: <TagsOutlined />,
      label: <Link to='/admin/categories'>Catégories</Link>,
    },
    {
      key: '/admin/depots',
      icon: <ShopOutlined />,
      label: <Link to='/admin/depots'>Dépôts</Link>,
    },
    {
      key: '/admin/suppliers',
      icon: <ShoppingOutlined />,
      label: <Link to='/admin/suppliers'>Fournisseurs</Link>,
    },
    {
      key: '/admin/deliveries',
      icon: <VerticalAlignBottomOutlined />,
      label: <Link to='/admin/deliveries'>Livraisons</Link>,
    },
    {
      key: '/bills',
      icon: <CopyOutlined />,
      label: <Link to='/bills'>Factures</Link>,
    },
    {
      key: '/items',
      icon: <UnorderedListOutlined />,
      label: <Link to='/items'>Produits</Link>,
    },
    {
      key: '/customers',
      icon: <UserOutlined />,
      label: <Link to='/customers'>Clients</Link>,
    },
    {
      key: '/logout',
      icon: <LoginOutlined />,
      label: 'Déconnexion',
      onClick: () => logoutHandler(),
    },
  ]

  const items = [
    {
      key: '/accueil',
      icon: <HomeOutlined />,
      label: <Link to='/'>Accueil</Link>,
    },
    {
      key: '/cart',
      icon: <ShoppingCartOutlined />,
      label: <Link to='/cart'>Panier</Link>,
    },
    {
      key: '/bills',
      icon: <CopyOutlined />,
      label: <Link to='/bills'>Factures</Link>,
    },
    {
      key: '/items',
      icon: <UnorderedListOutlined />,
      label: <Link to='/items'>Produits</Link>,
    },
    {
      key: '/customers',
      icon: <UserOutlined />,
      label: <Link to='/customers'>Clients</Link>,
    },
    {
      key: '/logout',
      icon: <LoginOutlined />,
      label: 'Déconnexion',
      onClick: () => logoutHandler(),
    },
  ]
  return (
    <Layout>
      {windowDimensions.width <= 975 ? (
        <>
          <Sider
            trigger={null}
            collapsedWidth={0}
            collapsible
            collapsed={collapsed}
          >
            <div className='logo'>
              <h3>{collapsed ? '' : 'MOGES-APP'}</h3>
            </div>
            <Menu
              theme='dark'
              mode='inline'
              defaultSelectedKeys={window.location.pathname}
              items={userInfo?.isAdmin ? adminItems : items}
            />
          </Sider>
        </>
      ) : (
        <>
          <Sider trigger={null} collapsible collapsed={!collapsed}>
            <div className='logo'>
              <h3>{!collapsed ? 'MA' : 'MOGES-APP'}</h3>
            </div>
            <Menu
              theme='dark'
              mode='inline'
              defaultSelectedKeys={window.location.pathname}
              items={userInfo?.isAdmin ? adminItems : items}
            />
          </Sider>
        </>
      )}
      <Layout className='site-layout'>
        <Header
          className='site-layout-background'
          style={{ padding: 10, background: colorBgContainer }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'trigger',
              onClick: toggle,
            }
          )}

          <div
            className='cart-count d-flex align-items-center '
            onClick={() => navigate('/cart')}
          >
            <b>
              <Badge
                className='mb-3 mr-2'
                count={cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
              ></Badge>
            </b>
            <ShoppingCartOutlined />
          </div>
        </Header>
        <Content
          className='site-layout-background'
          style={{
            margin: '24px 16px auto',
            padding: 24,
            minHeight: '280',

            background: colorBgContainer,
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default DefaultLayout
