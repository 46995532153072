import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import DefaultLayout from '../components/DefaultLayout'
import axios from 'axios'
import {
  EyeOutlined,
  EditOutlined,
  SearchOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Space,
  Table,
} from 'antd'
//import ReactToPrint from "react-to-print";
import { useReactToPrint } from 'react-to-print'
import { Store } from '../redux/Store'
import LoadingBox from '../components/LoadingBox'
import Highlighter from 'react-highlight-words'
import {
  functionReverse,
  PhoneNumberSplit,
  ToLocaleStringFunc,
} from '../components/DateReverse'
import { reducer } from '../redux/Reducer'
import { AccountSolde } from '../components/accountSolde'

function Bills() {
  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })

  const { state, dispatch: ctxDispatch } = useContext(Store)

  const { userInfo, billsPage } = state

  const [current, setCurrent] = useState(billsPage)

  const componentRef = useRef()
  const [billsData, setBillsData] = useState([])
  const [printBillModalVisibility, setPrintBillModalVisibilty] = useState(false)
  const [selectedBill, setSelectedBill] = useState(null)
  const [editingItem, setEditingItem] = useState(null)
  const [addEditModalVisibilty, setAddEditModalVisibilty] = useState(false)
  const [customers, setCustomers] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [compte, setCompte] = useState([])
  const searchInput = useRef(null)

  const monnaieRendu = selectedBill?.givenMoney - selectedBill?.subTotal

  console.log('selectedBill', selectedBill)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const getAllBills = async () => {
    try {
      const ac = new AbortController()
      const response = await axios.get(
        '/api/bills/get-all-bills',
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        },
        {
          signal: ac.signal,
        }
      )
      dispatch({ type: 'FETCH_SUCCESS' })
      const data = response.data
      data.reverse()
      setBillsData(data)
    } catch (error) {
      dispatch({ type: 'FETCH_FAIL' })
      console.log(error)
    }
  }

  const getAllCustomers = async () => {
    try {
      const ac = new AbortController()
      const response = await axios.get(
        '/api/customers/get-all-customers',
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        },
        { signal: ac.signal }
      )
      dispatch({ type: 'FETCH_SUCCESS' })
      setCustomers(response.data)
    } catch (error) {
      dispatch({ type: 'FETCH_FAIL' })
      console.log(error)
    }
  }

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' })
      await getAllCustomers()
    }
    fetchData()
    return () => ac.abort()
  }, [userInfo])

  const deleteBill = (record) => {
    dispatch({ type: 'DELETE_REQUEST' })
    axios
      .post(
        `/api/bills/delete-bill`,
        { billId: record._id },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      )
      .then((response) => {
        dispatch({ type: 'DELETE_SUCCESS' })
        message.success('Facture supprimée')
        getAllBills()
      })
      .catch((error) => {
        dispatch({ type: 'DELETE_FAIL' })
        message.error('Quelque chose ne va pas')
        console.log(error)
      })
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      ...getColumnSearchProps('createdAt'),
      render: (createdAt) => functionReverse(createdAt.substring(0, 10)),
    },
    {
      title: 'Client',
      dataIndex: 'customerName',
      ...getColumnSearchProps('customerName'),
      filters: customers.map((customer, index) => ({
        key: index,
        text: customer.name,
        value: customer.name,
      })),
      onFilter: (value, record) => record.customerName.indexOf(value) === 0,
      sorter: (a, b) => a.customerName.length - b.customerName.length,
      sortDirections: ['descend'],
      width: '30%',
    },
    {
      title: 'Payé',
      dataIndex: 'isPaid',
      render: (isPaid) => (isPaid === true ? `Oui` : 'Non'),
    },
    {
      title: 'Livré au client',
      dataIndex: 'isDelivered',
      render: (isDelivered) => (isDelivered === true ? 'Oui' : 'Non'),
    },
    {
      title: 'Méthode de paiement',
      dataIndex: 'paymentMode',
      ...getColumnSearchProps('paymentMode'),
    },
    {
      title: 'Total',
      dataIndex: 'totalAmount',
      ...getColumnSearchProps('totalAmount'),
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      render: (totalAmount) => ToLocaleStringFunc(totalAmount),
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      render: (id, record) => (
        <div className='d-flex'>
          <EyeOutlined
            className='mx-2'
            onClick={() => {
              setSelectedBill(record)
              setPrintBillModalVisibilty(true)
            }}
          />
          <EditOutlined
            className='mx-2'
            onClick={() => {
              setEditingItem(record)
              setAddEditModalVisibilty(true)
            }}
          />
          <DeleteOutlined className='mx-2' onClick={() => deleteBill(record)} />
        </div>
      ),
    },
  ]
  const onChange = (page) => {
    setCurrent(page?.current)
    ctxDispatch({ type: 'BILLS_PAGE', payload: page?.current })
    localStorage.setItem('billsPage', page?.current)
  }
  const cartcolumns = [
    {
      title: 'Nom',
      dataIndex: 'name',
    },
    {
      title: 'Prix',
      dataIndex: 'price',
    },
    {
      title: 'Quantité',
      dataIndex: '_id',
      render: (id, record) => (
        <div>
          <b>{record.quantity}</b>
        </div>
      ),
    },
    {
      title: 'Prix total',
      dataIndex: '_id',
      render: (id, record) => (
        <div>
          <b>{record.quantity * record.price}</b>
        </div>
      ),
    },
  ]

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      await getAllBills()
    }
    fetchData()
    return () => ac.abort()
  }, [])

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const onFinish = (values) => {
    dispatch({ type: 'UPDATE_REQUEST' })
    axios
      .post(
        '/api/bills/edit-bill',
        { ...values, billId: editingItem._id },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      )
      .then(() => {
        dispatch({ type: 'UPDATE_SUCCESS' })
        message.success('Facture modifiée')
        setEditingItem(null)
        setAddEditModalVisibilty(false)
        getAllBills()
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE_FAIL' })
        message.error('Quelque chose ne va pas')
        console.log(error)
      })
  }

  const customerPhone = customers?.find(
    (x) => x.name === selectedBill?.customerName
  )

  const soldeClient = AccountSolde(customerPhone?._id)

  return (
    <DefaultLayout>
      <div className='d-flex justify-content-between'>
        <h3>Factures</h3>
      </div>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        message.error('Quelque chose ne va pas')
      ) : (
        <Table
          columns={columns}
          dataSource={billsData}
          rowKey={(bill) => bill._id}
          onChange={onChange}
          pagination={{
            defaultCurrent: parseInt(current),
          }}
          bordered
          rowClassName={(record, index) =>
            record.isPaid === false ? 'red' : 'green'
          }
        />
      )}

      {addEditModalVisibilty && (
        <Modal
          onCancel={() => {
            setEditingItem(null)
            setAddEditModalVisibilty(false)
          }}
          open={addEditModalVisibilty}
          title='Modifier Facture'
          footer={false}
        >
          <Form
            initialValues={editingItem}
            layout='vertical'
            onFinish={onFinish}
          >
            <Form.Item name='customerName' label='Client'>
              <Input />
            </Form.Item>
            <Form.Item name='paymentMode' label='Méthode de paiement'>
              <Input />
            </Form.Item>
            <Form.Item name='totalAmount' label='Total'>
              <Input />
            </Form.Item>
            <Form.Item name='isPaid' label='Payé' valuePropName='checked'>
              <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item
              name='isDelivered'
              label='Livré au client'
              valuePropName='checked'
            >
              <Checkbox></Checkbox>
            </Form.Item>

            <div className='d-flex justify-content-end'>
              <Button htmlType='submit' type='primary'>
                Valider
              </Button>
            </div>
          </Form>
        </Modal>
      )}

      {printBillModalVisibility && (
        <Modal
          onCancel={() => {
            setPrintBillModalVisibilty(false)
          }}
          open={printBillModalVisibility}
          title={`Détail de la facture ${selectedBill._id}`}
          footer={false}
          width={800}
        >
          <div className='bill-model p-3' ref={componentRef}>
            <div className='d-flex justify-content-between bill-header pb-2'>
              <div>
                <h1>
                  <b>MOGES-APP</b>
                </h1>
              </div>
              <div>
                <p>Papy Shop</p>
                <p>Rue Peace</p>
                <p>9989649278</p>
              </div>
            </div>
            <div className='bill-customer-details my-2'>
              <p>
                <b>Client</b>: {selectedBill.customerName}
              </p>
              <p>
                <b>Tél</b>: {PhoneNumberSplit(customerPhone.contact)}
              </p>
              <p>
                <b>Date</b>:
                {functionReverse(
                  selectedBill.createdAt.toString().substring(0, 10)
                )}
              </p>
            </div>
            {loading ? (
              <LoadingBox />
            ) : error ? (
              message.error('Quelque chose ne va pas')
            ) : (
              <Table
                dataSource={selectedBill.cartItems}
                columns={cartcolumns}
                pagination={false}
                rowKey={(cart) => cart._id}
              />
            )}

            <div className='dotted-border'>
              <p>
                <b>SOUS TOTAL: </b>
                {ToLocaleStringFunc(selectedBill.subTotal)} CFA
              </p>
              <p>
                <b>Taxe:</b> {ToLocaleStringFunc(selectedBill.tax)} CFA
              </p>
              <div className='dotted-border'></div>
              <div>
                <h3>
                  <b>GRAND TOTAL: </b>
                  {ToLocaleStringFunc(selectedBill.totalAmount)} CFA
                </h3>
              </div>
              <div className='dotted-border'></div>
              <p>
                <b>SOMME REÇUE:</b>{' '}
                {selectedBill.givenMoney
                  ? `${selectedBill.givenMoney} CFA`
                  : 'Déduire du compte'}
              </p>
              <div className='dotted-border'></div>
              <h3>
                <b>MONNAIE RENDUE:</b>{' '}
                {selectedBill.givenMoney && monnaieRendu > 0
                  ? ToLocaleStringFunc(monnaieRendu)
                  : 0}{' '}
                CFA
              </h3>
              <div className='dotted-border'></div>

              {selectedBill.paymentMode === 'Déduire du compte' ? (
                <>
                  <h3>
                    <b>ANCIEN SOLDE:</b>{' '}
                    {ToLocaleStringFunc(soldeClient + selectedBill.totalAmount)}{' '}
                    CFA
                  </h3>
                </>
              ) : (
                ''
              )}

              <div className='dotted-border'></div>
              {soldeClient < 0 ? (
                <h3 style={{ color: '#893030' }}>
                  <b>VOTRE SOLDE ACTUEL:</b> {ToLocaleStringFunc(soldeClient)}{' '}
                  CFA
                </h3>
              ) : (
                <h3 style={{ color: '#448229' }}>
                  <b>VOTRE SOLDE: </b>
                  {ToLocaleStringFunc(soldeClient)} CFA
                </h3>
              )}
            </div>

            <div className='text-center'>
              <p>Merci</p>
              <p>À la prochaine visite 😃</p>
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <Button type='primary' onClick={handlePrint}>
              Imprimer facture
            </Button>
          </div>
        </Modal>
      )}
    </DefaultLayout>
  )
}

export default Bills
