import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import DefaultLayout from '../components/DefaultLayout'
import axios from 'axios'
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Modal, Space, Table } from 'antd'
import { Store } from '../redux/Store'
import LoadingBox from '../components/LoadingBox'
import ExpensesBilan from './ExpensesBilan'
import Highlighter from 'react-highlight-words'
import { functionReverse, ToLocaleStringFunc } from '../components/DateReverse'
import { reducer } from '../redux/Reducer'

function Depenses() {
  const [{ loading, error, loadingUpdate }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })

  const { state, dispatch: ctxDispatch } = useContext(Store)
  const { userInfo, depensePage } = state

  const [expensesData, setExpensesData] = useState([])
  const [addEditModalVisibilty, setAddEditModalVisibilty] = useState(false)
  const [editingItem, setEditingItem] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [current, setCurrent] = useState(depensePage)

  const onChange = (page) => {
    setCurrent(page?.current)
    ctxDispatch({ type: 'DEPENSE_PAGE', payload: page?.current })
    localStorage.setItem('depensePage', page?.current)
  }

  const searchInput = useRef(null)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const getAllExpenses = async () => {
    try {
      const ac = new AbortController()
      const response = await axios.get(
        '/api/expenses/all',
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        },
        {
          signal: ac.signal,
        }
      )
      dispatch({ type: 'FETCH_SUCCESS' })
      setExpensesData(response.data)
    } catch (error) {
      dispatch({ type: 'FETCH_FAIL' })
      console.log(error)
    }
  }

  const deleteExpense = (record) => {
    dispatch({ type: 'DELETE_REQUEST' })
    axios
      .post(
        `/api/expenses/delete-expense`,
        { expenseId: record._id },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        dispatch({ type: 'DELETE_SUCCESS' })
        message.success('Dépense supprimée')
        getAllExpenses()
      })
      .catch((error) => {
        dispatch({ type: 'DELETE_FAIL' })
        message.error('Quelque chose ne va pas')
        console.log(error)
      })
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      ...getColumnSearchProps('createdAt'),
      render: (createdAt) => functionReverse(createdAt.substring(0, 10)),
      sorter: (a, b) => a.createdAt.length - b.createdAt.length,
      width: '30%',
    },
    {
      title: 'Inititulé de la dépense',
      dataIndex: 'title',
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Montant',
      dataIndex: 'amount',
      ...getColumnSearchProps('amount'),
      render: (amount) => ToLocaleStringFunc(amount),
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      render: (id, record) => (
        <div className='d-flex'>
          <EditOutlined
            className='mx-2'
            onClick={() => {
              setEditingItem(record)
              setAddEditModalVisibilty(true)
            }}
          />

          <DeleteOutlined
            className='mx-2'
            onClick={() => deleteExpense(record)}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' })
      await getAllExpenses()
    }
    fetchData()
    return () => ac.abort()
  }, [userInfo])

  const onFinish = (values) => {
    if (editingItem === null) {
      dispatch({ type: 'FETCH_REQUEST' })
      axios
        .post('/api/expenses/new', values, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        })
        .then((response) => {
          dispatch({ type: 'FETCH_SUCCESS' })
          message.success('Dépense ajoutée')
          setAddEditModalVisibilty(false)
          getAllExpenses()
        })
        .catch((error) => {
          dispatch({ type: 'FETCH_FAIL' })
          message.error('Quelque chose ne va pas')
          console.log(error)
        })
    } else {
      dispatch({ type: 'UPDATE_REQUEST' })
      axios
        .post(
          '/api/expenses/edit-expense',
          { ...values, expenseId: editingItem._id },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        )
        .then((response) => {
          dispatch({ type: 'UPDATE_SUCCESS' })
          message.success('Dépense modifiée')
          setEditingItem(null)
          setAddEditModalVisibilty(false)
          getAllExpenses()
        })
        .catch((error) => {
          dispatch({ type: 'UPDATE_FAIL' })
          message.error('Quelque chose ne va pas')
          console.log(error)
        })
    }
  }

  return (
    <DefaultLayout>
      <div className='d-flex justify-content-between'>
        <h3>Dépenses</h3>
        <Button type='primary' onClick={() => setAddEditModalVisibilty(true)}>
          Ajouter
        </Button>
      </div>
      <div>
        <ExpensesBilan />
      </div>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        message.error('Quelque chose ne va pas')
      ) : (
        <Table
          columns={columns}
          dataSource={expensesData}
          rowKey={(expense) => expense._id}
          bordered
          onChange={onChange}
          pagination={{
            defaultCurrent: parseInt(current),
          }}
          rowClassName={'red'}
        />
      )}
      {addEditModalVisibilty && (
        <Modal
          onCancel={() => {
            setEditingItem(null)
            setAddEditModalVisibilty(false)
          }}
          open={addEditModalVisibilty}
          title={`${
            editingItem !== null
              ? 'Modifier dépense'
              : 'Ajouter nouvelle dépense'
          }`}
          footer={false}
        >
          <Form
            initialValues={editingItem}
            layout='vertical'
            onFinish={onFinish}
          >
            <Form.Item name='title' label='Intitulé de la dépense'>
              <Input />
            </Form.Item>
            <Form.Item name='amount' label='Montant'>
              <Input />
            </Form.Item>

            <div className='d-flex justify-content-end'>
              <Button htmlType='submit' type='primary'>
                Valider
              </Button>
              {loadingUpdate && <LoadingBox />}
            </div>
          </Form>
        </Modal>
      )}
    </DefaultLayout>
  )
}

export default Depenses
