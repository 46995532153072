import { Button, message } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import DefaultLayout from '../components/DefaultLayout'
import LoadingBox from '../components/LoadingBox'
import { Store } from '../redux/Store'
import { reducer } from '../redux/Reducer'
import BackBtn from '../components/BackBtn'
import GererStockageDepot from './GererStockageDepot'

function DepotDetail() {
  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })

  const { state, dispatch: ctxDispatch } = useContext(Store)
  const { userInfo } = state
  const params = useParams()
  const { id } = params

  const [name, setName] = useState('')
  const [address, setAddress] = useState('')

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' })
        const { data } = await axios.get(
          `/api/depots/${id}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          },
          { signal: ac.signal }
        )
        setName(data.name)
        setAddress(data.address)
        dispatch({ type: 'FETCH_SUCCESS' })
        ctxDispatch({ type: 'CREATE_ DEPOT', payload: data })
        localStorage.setItem('depotInfo', JSON.stringify(data))
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: message.error(err),
        })
      }
    }
    fetchData()
    return () => ac.abort()
  }, [userInfo, id, ctxDispatch])

  return (
    <DefaultLayout>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        message.error('Quelque chose ne va pas')
      ) : (
        <>
          <div className='d-flex justify-content-between'>
            <BackBtn />

            <Button className='mx-2' type='primary'>
              <Link to='/admin/stock-transaction/new'>Ajouter transaction</Link>
            </Button>
          </div>
          <div className='d-flex justify-content-between'>
            <h3>{name}</h3>
          </div>
          <p style={{ fontSize: '18px' }}>Adresse: {address}</p>
        </>
      )}

      <GererStockageDepot />
    </DefaultLayout>
  )
}

export default DepotDetail
