import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
  useRef,
} from 'react'
import DefaultLayout from '../components/DefaultLayout'
import axios from 'axios'
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Table,
  Upload,
} from 'antd'
import { Store } from '../redux/Store'
import LoadingBox from '../components/LoadingBox'
import Highlighter from 'react-highlight-words'
import { reducer } from '../redux/Reducer'
import {
  ToLocaleStringFunc,
  capitalizeFirstLetter,
} from '../components/DateReverse'

function Items() {
  const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: '',
    })

  const { state, dispatch: ctxDispatch } = useContext(Store)
  const { userInfo, productPage } = state

  const [itemsData, setItemsData] = useState([])
  const [addEditModalVisibilty, setAddEditModalVisibilty] = useState(false)
  const [categories, setCategories] = useState([])
  const [fournisseurs, setFournisseurs] = useState([])
  const [editingItem, setEditingItem] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [image, setImage] = useState('')

  const [current, setCurrent] = useState(productPage)

  const onChange = (page) => {
    setCurrent(page?.current)
    ctxDispatch({ type: 'PRODUCT_PAGE', payload: page?.current })
    localStorage.setItem('productPage', page?.current)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const getAllItems = async () => {
    try {
      const ac = new AbortController()
      const response = await axios.get(
        '/api/items/get-all-items',
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        },
        {
          signal: ac.signal,
        }
      )
      dispatch({ type: 'FETCH_SUCCESS' })
      setItemsData(response.data)
    } catch (error) {
      dispatch({ type: 'FETCH_FAIL' })
      console.log(error)
    }
  }

  const getAllFournisseurs = async () => {
    try {
      const ac = new AbortController()
      const response = await axios.get(
        '/api/fournisseurs/get-all-fournisseurs',
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        },
        {
          signal: ac.signal,
        }
      )
      dispatch({ type: 'FETCH_SUCCESS' })
      setFournisseurs(response.data)
    } catch (error) {
      dispatch({ type: 'FETCH_FAIL' })
      console.log(error)
    }
  }

  const getAllCategories = async () => {
    try {
      const ac = new AbortController()
      const response = await axios.get(
        '/api/categories/all',
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        },
        {
          signal: ac.signal,
        }
      )
      dispatch({ type: 'FETCH_SUCCESS' })
      setCategories(response.data)
    } catch (error) {
      dispatch({ type: 'FETCH_FAIL' })
      console.log(error)
    }
  }

  const deleteItem = (record) => {
    dispatch({ type: 'DELETE_REQUEST' })
    axios
      .post(
        '/api/items/delete-item',
        { itemId: record._id },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      )
      .then((response) => {
        dispatch({ type: 'DELETE_SUCCESS' })
        message.success('Produit supprimé')
        getAllItems()
      })
      .catch((error) => {
        dispatch({ type: 'DELETE_FAIL' })
        message.error('Quelque chose ne va pas')
        console.log(error)
      })
  }

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Image',
      dataIndex: 'image',
      render: (image, record) => (
        <img src={image} alt='' height='60' width='60' />
      ),
    },
    {
      title: 'Prix',
      dataIndex: 'price',
      ...getColumnSearchProps('price'),
      render: (price) => ToLocaleStringFunc(price),
    },
    {
      title: 'Stock',
      dataIndex: 'countInStock',
      ...getColumnSearchProps('countInStock'),
      render: (countInStock) => ToLocaleStringFunc(countInStock),
    },
    {
      title: 'Stock de réserve',
      dataIndex: 'countInReserveStock',
      ...getColumnSearchProps('countInReserveStock'),
      render: (countInReserveStock) => ToLocaleStringFunc(countInReserveStock),
    },
    {
      title: 'Packaging',
      dataIndex: 'packaging',
      ...getColumnSearchProps('packaging'),
    },
    {
      title: 'Categorie',
      dataIndex: 'category',
      ...getColumnSearchProps('category'),
      filters: categories.map((category, index) => ({
        key: category.name,
        text: category.name,
        value: category.name,
      })),
      onFilter: (value, record) => record.category.indexOf(value) === 0,
      sorter: (a, b) => a.category.length - b.category.length,
    },
    {
      title: 'Fournisseur',
      dataIndex: 'supplierName',
      ...getColumnSearchProps('supplierName'),
      sorter: (a, b) => a.supplierName.length - b.supplierName.length,
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      render: (id, record) => (
        <div className='d-flex'>
          <EditOutlined
            className='mx-2'
            onClick={() => {
              setEditingItem(record)
              setAddEditModalVisibilty(true)
            }}
          />
          <DeleteOutlined className='mx-2' onClick={() => deleteItem(record)} />
        </div>
      ),
    },
  ]

  useEffect(() => {
    const ac = new AbortController()

    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' })
      await getAllItems()
    }
    fetchData()
    return () => ac.abort()
  }, [userInfo])

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' })
      await getAllCategories()
    }
    fetchData()
    return () => ac.abort()
  }, [userInfo])

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' })
      await getAllFournisseurs()
    }
    fetchData()
    return () => ac.abort()
  }, [userInfo])

  const onFinish = (values) => {
    if (editingItem === null) {
      dispatch({ type: 'FETCH_REQUEST' })
      axios
        .post(
          '/api/items/add-item',
          { ...values, image: image },
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        )
        .then((response) => {
          dispatch({ type: 'FETCH_SUCCESS' })
          message.success(response.data.message)
          setAddEditModalVisibilty(false)
          setImage('')
          getAllItems()
        })
        .catch((error) => {
          dispatch({ type: 'FETCH_FAIL' })
          message.error('Quelque chose ne va pas')
          console.log(error)
        })
    } else {
      dispatch({ type: 'UPDATE_REQUEST' })
      axios
        .post(
          '/api/items/edit-item',
          {
            ...values,
            itemId: editingItem._id,
            image: image ? image : editingItem.image,
          },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        )
        .then((response) => {
          dispatch({ type: 'UPDATE_SUCCESS' })
          message.success(response.data.message)
          setEditingItem(null)
          setAddEditModalVisibilty(false)
          setImage('')
          getAllItems()
        })
        .catch((error) => {
          dispatch({ type: 'UPDATE_FAIL' })
          message.error('Quelque chose ne va pas')
          console.log(error)
        })
    }
  }

  const props = {
    name: 'file',
    action: '/api/upload',
    headers: {
      authorization: `Bearer ${userInfo.token}`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} téléchargée`)
        setImage(info.file.response.secure_url)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  return (
    <DefaultLayout>
      <div className='d-flex justify-content-between'>
        <h3>Produits</h3>
        <Button type='primary' onClick={() => setAddEditModalVisibilty(true)}>
          Ajouter
        </Button>
      </div>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        message.error('Quelque chose ne va pas')
      ) : (
        <Table
          columns={columns}
          dataSource={itemsData}
          rowKey={(item) => item._id}
          onChange={onChange}
          pagination={{
            defaultCurrent: parseInt(current),
          }}
          bordered
          rowClassName={(record, index) =>
            record.countInStock <= 15 ? 'red' : ''
          }
        />
      )}

      {addEditModalVisibilty && (
        <Modal
          onCancel={() => {
            setEditingItem(null)
            setAddEditModalVisibilty(false)
          }}
          open={addEditModalVisibilty}
          title={`${
            editingItem !== null
              ? 'Modidifier Produit'
              : 'Ajouter Nouveau Produit'
          }`}
          footer={false}
        >
          <Form
            initialValues={editingItem}
            layout='vertical'
            onFinish={onFinish}
          >
            <Form.Item name='name' label='Nom'>
              <Input />
            </Form.Item>
            <Form.Item name='price' label='Prix'>
              <Input />
            </Form.Item>
            <Upload listType='picture' {...props}>
              <Button icon={<UploadOutlined />}>Télécharger Image</Button>
            </Upload>
            <br />

            <Form.Item
              name='image'
              label='Image URL'
              value={image}
              onChange={(e) => setImage(e.target.value)}
            >
              <Input />
            </Form.Item>

            <Form.Item name='countInStock' label='En Stock'>
              <Input />
            </Form.Item>
            <Form.Item name='countInReserveStock' label='Stock de réserve'>
              <Input />
            </Form.Item>
            <Form.Item name='packaging' label='Packaging'>
              <Input />
            </Form.Item>
            <Form.Item name='supplierName' label='Fournisseur'>
              <Select>
                {fournisseurs.map((supplier, index) => {
                  return (
                    <Select.Option key={index} value={supplier.name}>
                      {supplier.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>

            <Form.Item name='category' label='Category'>
              <Select>
                {categories.map((category, index) => {
                  return (
                    <Select.Option key={index} value={category.name}>
                      {capitalizeFirstLetter(`${category.name}`)}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>

            <div className='d-flex justify-content-end'>
              <Button htmlType='submit' type='primary'>
                Valider
              </Button>
              {loadingUpdate && <LoadingBox />}
            </div>
          </Form>
        </Modal>
      )}
    </DefaultLayout>
  )
}

export default Items
