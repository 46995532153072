import { Card, Col, Row } from 'antd'
import React from 'react'
import { ToLocaleStringFunc } from '../components/DateReverse'
import { GetBilanDettes } from '../components/GetBilanDettes'

const BilanDette = () => {
  const totalDette = GetBilanDettes()
    ?.compte?.map((elt) => (elt.totalAmount < 0 ? elt.totalAmount : 0))
    .reduce((a, b) => a + b, 0)

  return (
    <div>
      <h3>Dettes</h3>
      <>
        <Row gutter={16}>
          <Col flex={4}>
            <Card bordered={true} className='info-dash-expense'>
              <h4>{ToLocaleStringFunc(totalDette)} CFA</h4>
              <p>Total des dettes</p>
            </Card>
          </Col>
          <Col flex={4}>
            <Card bordered={true} className='info-dash-expense'>
              <h4>
                {ToLocaleStringFunc(
                  GetBilanDettes()?.detteCurrentMonthPreview?.[0]
                    ?.yesterday?.[0]?.totalDettes
                )}{' '}
                CFA
              </h4>
              <p>Hier</p>
            </Card>
          </Col>
          <Col flex={4}>
            <Card bordered={true} className='info-dash-expense'>
              <h4>
                {ToLocaleStringFunc(
                  GetBilanDettes()?.detteCurrentMonthPreview?.[0]?.today?.[0]
                    ?.totalDettes
                )}{' '}
                CFA
              </h4>

              <p>Aujourd'hui</p>
            </Card>
          </Col>

          <Col flex={4}>
            <Card bordered={true} className='info-dash-expense'>
              <h4>
                {ToLocaleStringFunc(
                  GetBilanDettes()?.detteCurrentMonthPreview?.[0]?.month?.[0]
                    ?.totalDettes
                )}{' '}
                CFA
              </h4>
              <p>Ce mois-ci</p>
            </Card>
          </Col>
        </Row>
      </>
    </div>
  )
}

export default BilanDette
