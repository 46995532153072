import React, { useContext, useEffect, useReducer } from 'react'
import Chart from 'react-google-charts'
import axios from 'axios'
import { Store } from '../redux/Store'
import { Card, Col, message, Row } from 'antd'
import DefaultLayout from '../components/DefaultLayout'
import LoadingBox from '../components/LoadingBox'
import '../resourses/infodash.css'
import { reducer } from '../redux/Reducer'
import { functionReverse, ToLocaleStringFunc } from '../components/DateReverse'
import BilanDette from './BilanDette'
import BilanRemboursement from './BilanRemboursement'

/**Ignore google charts warning */
const originalWarn = console.warn

console.warn = function (...args) {
  const arg = args && args[0]

  if (arg && arg.includes("Attempting to load version '51' of Google Charts"))
    return

  originalWarn(...args)
}
/**End ignore google charts warnings */

function Bilans() {
  const [{ loading, summary, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })

  //console.log(summary)

  const [messageApi, contextHolder] = message.useMessage()

  const warning = (warningmessage) => {
    messageApi.open({
      type: 'warning',
      content: warningmessage,
    })
  }
  const errormessage = (errmessage) => {
    messageApi.open({
      type: 'error',
      content: errmessage,
    })
  }

  const { state } = useContext(Store)
  const { userInfo } = state

  const date = 'date'
  const sales = 'ventes'
  const remboursements = 'remb.'
  const dettes = 'dettes'
  const count = 'quantité'
  const category = 'catégories'
  const products = 'produits'
  const customer = 'clients'
  const totalSales = 'total ventes par client'
  const nbreOrders = 'total commandes'

  const totalVentes = ToLocaleStringFunc(summary?.orders?.[0]?.totalSales)

  const monthStrings = [
    'Jan',
    'Fév',
    'Mar',
    'Avr',
    'Mai',
    'Juin',
    'Juil',
    'Août',
    'Sep',
    'Oct',
    'Nov',
    'Déc',
  ]

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          '/api/bills/summary',
          { signal: ac.signal },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        )

        dispatch({ type: 'FETCH_SUCCESS_SUMMARY', payload: data })
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: errormessage(err),
        })
      }
    }
    fetchData()
    return () => {
      ac.abort()
    }
  }, [userInfo])

  return (
    <DefaultLayout>
      {contextHolder}
      <h2>Bilans</h2>
      <h3>Ventes</h3>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        errormessage('Quelque chose ne va pas')
      ) : (
        <>
          <Row gutter={16}>
            <Col flex={4}>
              <Card bordered={true} className='info-dash'>
                <h4>{ToLocaleStringFunc(summary?.orders?.[0]?.numOrders)}</h4>
                <p>Commandes</p>
              </Card>
            </Col>
            <Col flex={4}>
              <Card bordered={true} className='info-dash'>
                <h4>{totalVentes} CFA</h4>
                <p>Total Ventes</p>
              </Card>
            </Col>
            <Col flex={4}>
              <Card bordered={true} className='info-dash'>
                <h4>
                  {ToLocaleStringFunc(
                    summary?.currentMonthPreview?.[0]?.yesterday?.[0]
                      ? summary?.currentMonthPreview?.[0]?.yesterday?.[0]
                          ?.totalSales
                      : 0
                  )}{' '}
                  CFA
                </h4>
                <p>Hier</p>
              </Card>
            </Col>
            <Col flex={4}>
              <Card bordered={true} className='info-dash'>
                <h4>
                  {ToLocaleStringFunc(
                    summary?.currentMonthPreview?.[0]?.today?.[0]
                      ? summary?.currentMonthPreview?.[0]?.today?.[0]
                          ?.totalSales
                      : 0
                  )}{' '}
                  CFA
                </h4>

                <p>Aujourd'hui</p>
              </Card>
            </Col>

            <Col flex={4}>
              <Card bordered={true} className='info-dash'>
                <h4>
                  {ToLocaleStringFunc(
                    summary?.currentMonthPreview?.[0]?.month?.[0]
                      ? summary?.currentMonthPreview?.[0]?.month?.[0]
                          ?.totalSales
                      : 0
                  )}{' '}
                  CFA
                </h4>
                <p>Ce mois-ci</p>
              </Card>
            </Col>
          </Row>
          <hr />
          <BilanDette />
          <hr />
          <BilanRemboursement />
          <hr />

          <div className='my-4'>
            {summary?.dailyOrders?.length === 0 ? (
              warning('Pas de vente')
            ) : (
              <Row>
                <Col flex={4}>
                  <h4>Ventes par jour</h4>
                  <Chart
                    width='100%'
                    height='400px'
                    chartType='AreaChart'
                    loader={<LoadingBox />}
                    data={[
                      [date, sales],
                      ...summary?.dailyOrders?.map((x) => [
                        functionReverse(x._id),
                        x.sales,
                      ]),
                    ]}
                  />
                </Col>
                <Col flex={4}>
                  <h4>Nombre de ventes par jours</h4>
                  <Chart
                    width='100%'
                    height='400px'
                    chartType='Line'
                    loader={<LoadingBox />}
                    data={[
                      [date, count],
                      ...summary?.dailyOrders?.map((x) => [
                        functionReverse(x._id),
                        x.orders,
                      ]),
                    ]}
                  />
                </Col>
              </Row>
            )}
          </div>
          <Row>
            <Col flex={4}>
              <div className='my-3'>
                <h4 style={{ color: '#4b48a3' }}>Bilan mensuel des ventes</h4>
                {summary?.totalMonthly?.length === 0 ? (
                  warning('Pas de bilan annuel')
                ) : (
                  <>
                    <Chart
                      width='100%'
                      height='400px'
                      chartType='ColumnChart'
                      loader={<LoadingBox />}
                      data={[
                        [date, sales, { role: 'style' }],
                        ...summary?.totalMonthly?.map((x) => [
                          monthStrings[x.x - 1],
                          x.y,
                          '#4b48a3',
                        ]),
                      ]}
                    />
                  </>
                )}
              </div>
            </Col>
            <Col flex={4}>
              <div className='my-3'>
                <h4 style={{ color: '#893030' }}>Bilan mensuel des dettes</h4>
                {summary?.detteTotalMonthly?.length === 0 ? (
                  warning('Pas de bilan annuel')
                ) : (
                  <>
                    <Chart
                      width='100%'
                      height='400px'
                      chartType='ColumnChart'
                      loader={<LoadingBox />}
                      data={[
                        [date, dettes, { role: 'style' }],
                        ...summary?.detteTotalMonthly?.map((x) => [
                          monthStrings[x.x - 1],
                          -1 * x.y,
                          '#893030',
                        ]),
                      ]}
                    />
                  </>
                )}
              </div>
            </Col>
            <Col flex={4}>
              <div className='my-3'>
                <h4 style={{ color: '#448229' }}>
                  Bilan mensuel des remboursements
                </h4>
                {summary?.remboursementTotalMonthly?.length === 0 ? (
                  warning('Pas de bilan annuel')
                ) : (
                  <>
                    <Chart
                      width='100%'
                      height='400px'
                      chartType='ColumnChart'
                      loader={<LoadingBox />}
                      data={[
                        [date, remboursements, { role: 'style' }],
                        ...summary?.remboursementTotalMonthly?.map((x) => [
                          monthStrings[x.x - 1],
                          x.y,
                          '#448229',
                        ]),
                      ]}
                    />
                  </>
                )}
              </div>
            </Col>
          </Row>

          <div className='my-4'>
            {summary?.dailyOrders?.length === 0 ? (
              warning('Pas de ventes')
            ) : (
              <Row>
                <Col flex={4}>
                  <h4>Total ventes par client</h4>
                  <Chart
                    width='100%'
                    height='400px'
                    chartType='AreaChart'
                    loader={<LoadingBox />}
                    data={[
                      [customer, totalSales],
                      ...summary?.salesbyCustomer?.map((x) => [
                        functionReverse(x._id),
                        x.totalSalesByCustomer,
                      ]),
                    ]}
                  />
                </Col>
                <Col flex={4}>
                  <h4>Total commandes par client</h4>
                  <Chart
                    width='100%'
                    height='400px'
                    chartType='Line'
                    loader={<LoadingBox />}
                    data={[
                      [customer, nbreOrders],
                      ...summary?.salesbyCustomer?.map((x) => [
                        functionReverse(x._id),
                        x.orders,
                      ]),
                    ]}
                  />
                </Col>
              </Row>
            )}
          </div>
          <Row>
            <Col flex={4}>
              <div className='my-3'>
                <h4>Catégories</h4>
                {summary?.produitCategories?.length === 0 ? (
                  warning('Pas de catégorie')
                ) : (
                  <Chart
                    width='100%'
                    height='400px'
                    chartType='PieChart'
                    loader={<LoadingBox />}
                    data={[
                      [category, products],
                      ...summary?.quantityByCategory?.map((x) => [
                        functionReverse(x._id),
                        x.sum,
                      ]),
                    ]}
                  />
                )}
              </div>
            </Col>
          </Row>
        </>
      )}
    </DefaultLayout>
  )
}

export default Bilans
