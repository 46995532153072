import React, { useContext, useEffect, useReducer } from 'react'
import axios from 'axios'
import { Store } from '../redux/Store'
import { Card, Col, message, Row } from 'antd'
import '../resourses/infodash.css'
import { reducer } from '../redux/Reducer'
import { ToLocaleStringFunc } from '../components/DateReverse'

function Bilans() {
  const [{ loading, expenceSummary }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })

  const { state } = useContext(Store)
  const { userInfo } = state

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          '/api/expenses/expense-summary',
          { signal: ac.signal },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        )

        dispatch({ type: 'FETCH_SUCCESS_SUMMARY', payload: data })
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: message.error(err),
        })
      }
    }
    fetchData()
    return () => {
      ac.abort()
    }
  }, [userInfo])

  return (
    <>
      <h6>Dépenses</h6>

      <Row gutter={16}>
        <Col flex={4}>
          <Card bordered={true} className='info-dash-expense'>
            <h4>
              {ToLocaleStringFunc(expenceSummary?.expenses?.[0]?.numExpenses)}
            </h4>
            <p>Dépenses</p>
          </Card>
        </Col>
        <Col flex={4}>
          <Card bordered={true} className='info-dash-expense'>
            <h4>
              {ToLocaleStringFunc(expenceSummary?.expenses?.[0]?.totalExpenses)}{' '}
              CFA
            </h4>
            <p>Total Dépenses</p>
          </Card>
        </Col>
        <Col flex={4}>
          <Card bordered={true} className='info-dash-expense'>
            <h4>
              {ToLocaleStringFunc(
                expenceSummary?.currentMonthPreviewExpense?.[0]?.yesterday?.[0]
                  ?.totalExpenses
              )}{' '}
              CFA
            </h4>
            <p>Hier</p>
          </Card>
        </Col>
        <Col flex={4}>
          <Card bordered={true} className='info-dash-expense'>
            <h4>
              {ToLocaleStringFunc(
                expenceSummary?.currentMonthPreviewExpense?.[0]?.today?.[0]
                  ?.totalExpenses
              )}{' '}
              CFA
            </h4>

            <p>Aujourd'hui</p>
          </Card>
        </Col>

        <Col flex={4}>
          <Card bordered={true} className='info-dash-expense'>
            <h4>
              {ToLocaleStringFunc(
                expenceSummary?.currentMonthPreviewExpense?.[0]?.month?.[0]
                  ?.totalExpenses
              )}
              CFA
            </h4>
            <p>Ce mois-ci</p>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Bilans
