import { Button, message } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import DefaultLayout from '../components/DefaultLayout'
import LoadingBox from '../components/LoadingBox'
import { Store } from '../redux/Store'
import FactureByLivraison from './FactureByLivraison'
import { reducer } from '../redux/Reducer'
import { ToLocaleStringFunc } from '../components/DateReverse'
import BackBtn from '../components/BackBtn'

function ClientDetail() {
  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })

  const { state, dispatch: ctxDispatch } = useContext(Store)
  const { userInfo } = state
  const params = useParams()
  const { id } = params
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [itemName, setItemName] = useState('')
  const [itemCondition, setItemCondition] = useState('')
  const [itemQuantity, setItemQuantity] = useState('')
  const [itemPrice, setItemPrice] = useState(0)

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' })
        const { data } = await axios.get(
          `/api/suppliers/${id}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          },
          { signal: ac.signal }
        )
        setName(data.name)
        setItemName(data.itemName)
        setItemCondition(data.itemCondition)
        setItemQuantity(data.itemQuantity)
        setItemPrice(data.itemPrice)
        dispatch({ type: 'FETCH_SUCCESS' })
        ctxDispatch({ type: 'CREATE_SUPPLIER', payload: data })
        localStorage.setItem('supplierInfo', JSON.stringify(data))
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: message.error(err),
        })
      }
    }
    fetchData()
    return () => ac.abort()
  }, [userInfo, id, ctxDispatch])

  return (
    <DefaultLayout>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        message.error('Quelque chose ne va pas')
      ) : (
        <>
          <div className='d-flex justify-content-between'>
            <BackBtn className='mr-3' />
            <Button
              type='primary'
              onClick={() => navigate('/admin/facturelivraison/new')}
            >
              Ajouter facture livraison
            </Button>
          </div>
          <div className='d-flex justify-content-between'>
            <h3>Détail de la livraison</h3>
          </div>
          <p style={{ fontSize: '20px' }}>
            Fournisseur: <b>{name}</b>
            <br />
            Produit: <b>{itemName}</b>
            <br />
            Packaging: <b>{itemCondition}</b>
            <br />
            Quantité: <b>{ToLocaleStringFunc(itemQuantity)}</b> <br />
            Prix: <b>{ToLocaleStringFunc(itemPrice)} CFA</b>
          </p>

          <FactureByLivraison />
        </>
      )}
    </DefaultLayout>
  )
}

export default ClientDetail
