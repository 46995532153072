import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Button, Col, Form, Input, message, Row } from 'antd'
import '../resourses/authentication.css'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Store } from '../redux/Store'
import LoadingBox from '../components/LoadingBox'
import { reducer } from '../redux/Reducer'

function UpdateUser() {
  const { state } = useContext(Store)
  const { userInfo } = state
  const params = useParams()
  const { id } = params
  const [{ loading, error, loadingUpdate }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [transactionAmount, setTransactionAmount] = useState('')
  const [customerId, setCustomerId] = useState('')

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' })
        const { data } = await axios.get(
          `/api/transactions/${id}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          },
          { signal: ac.signal }
        )
        setName(data.name)
        setPaymentMethod(data.paymentMethod)
        setTransactionAmount(data.transactionAmount)
        setCustomerId(data.customerId)

        dispatch({ type: 'FETCH_SUCCESS', payload: data })
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: err,
        })
      }
    }
    fetchData()
    return () => ac.abort()
  }, [userInfo, id])

  const onFinish = async () => {
    try {
      dispatch({ type: 'UPDATE_REQUEST' })
      await axios.put(
        `/api/transactions/${id}`,
        {
          _id: id,
          name,
          transactionAmount,
          paymentMethod,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      )
      dispatch({ type: 'UPDATE_SUCCESS' })
      message.success('Transaction modifiée')
      navigate(`/admin/customer/${customerId}`)
    } catch (error) {
      dispatch({ type: 'UPDATE_FAIL' })
      message.error('Quelque chose ne va pas', error)
    }
  }

  return (
    <div className='authentication'>
      <Row>
        <Col lg={8} xs={22}>
          {loading ? (
            <LoadingBox />
          ) : error ? (
            message.error('Quelque chose ne va pas')
          ) : (
            <>
              <Form layout='vertical' onFinish={onFinish}>
                <h1>
                  <b>MOGES-APP</b>
                </h1>
                <hr />
                <h3>Modifier Transaction</h3>

                <Form.Item
                  name={['transaction', 'name']}
                  label='Intitulé'
                  initialValue={name}
                >
                  <Input onChange={(e) => setName(e.target.value)} />
                </Form.Item>
                <Form.Item
                  name={['transaction', 'paymentMethod']}
                  label='Méthode de paiement'
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  initialValue={paymentMethod}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name={['transaction', 'transactionAmount']}
                  label='Montant de la transaction'
                  onChange={(e) => setTransactionAmount(e.target.value)}
                  initialValue={transactionAmount}
                >
                  <Input />
                </Form.Item>

                <div className='d-flex justify-content-between align-items-center'>
                  <Button htmlType='submit' type='primary'>
                    Valider
                  </Button>
                  {loadingUpdate && <LoadingBox />}
                </div>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default UpdateUser
