import { Card, Col, message, Row, Table } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Store } from '../redux/Store'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import LoadingBox from '../components/LoadingBox'
import { reducer } from '../redux/Reducer'
import { ToLocaleStringFunc } from '../components/DateReverse'

function FactureByLivraison() {
  const [{ loading, error, successDelete }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })
  const { state } = useContext(Store)
  const { userInfo } = state
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params

  const [compte, setCompte] = useState([])

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `/api/facturelivraison/${id}/facture-livraison`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          },
          { signal: ac.signal }
        )
        dispatch({ type: 'FETCH_SUCCESS', payload: data })
        setCompte(data)
      } catch (error) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: message.error(error),
        })
      }
    }
    if (successDelete) {
      dispatch({ type: 'DELETE_RESET' })
    } else {
      fetchData()
    }
    return () => ac.abort()
  }, [userInfo, successDelete, id])

  const refreshPage = () => {
    window.location.reload()
  }

  const deleteHandler = async (transactionId) => {
    try {
      dispatch({ type: 'DELETE_REQUEST' })
      await axios.delete(`/api/facturelivraison/${transactionId}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      })
      message.success(`Transaction Deleted Successfully`)
      dispatch({ type: 'DELETE_SUCCESS' })
      refreshPage()
    } catch (err) {
      message.error(err)
    }
  }

  const totalVersement = compte.compte
    ?.map((elt) => (elt._id === id ? elt.totalAmount : 0))
    .reduce((a, b) => a + b, 0)

  const livraisonPrice = compte.factureLivraisons?.[0]?.supplierId.itemPrice

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (createdAt) => createdAt.substring(0, 10),
    },
    {
      title: 'Intitulé de la transaction',
      dataIndex: 'name',
    },
    {
      title: 'Moyen de paiement',
      dataIndex: 'paymentMethod',
    },
    {
      title: 'Montant',
      dataIndex: 'transactionAmount',
      render: (transactionAmount) => ToLocaleStringFunc(transactionAmount),
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      render: (id, record) => (
        <div className='d-flex'>
          <EditOutlined
            className='mx-2'
            onClick={() => navigate(`/admin/facturelivraison/${record._id}`)}
          />
          <DeleteOutlined
            className='mx-2'
            onClick={() => deleteHandler(record._id)}
          />
        </div>
      ),
    },
  ]
  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        message.error('Quelque chose ne va pas')
      ) : (
        <>
          <div className='d-flex justify-content-between my-4'>
            <Row gutter={16}>
              <Col flex={4}>
                <Card bordered={true} className='info-dash'>
                  <h4>{ToLocaleStringFunc(totalVersement)} CFA</h4>
                  <p>Total versé pour la livraison</p>
                </Card>
                {totalVersement === livraisonPrice ? (
                  <div style={{ color: 'green', paddingLeft: '15px' }}>
                    <b>Livraison soldée</b>
                  </div>
                ) : (
                  <div style={{ color: 'red', paddingLeft: '15px' }}>
                    <b>Livraison non soldé</b>
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <hr />
          <h3 className='my-4'>Détail des versements</h3>
          <Table
            columns={columns}
            dataSource={compte.factureLivraisons}
            rowKey={(item) => item._id}
            bordered
          />
        </>
      )}
    </>
  )
}

export default FactureByLivraison
