import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Button, Col, Form, Input, message, Row, Select } from 'antd'
import '../resourses/authentication.css'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Store } from '../redux/Store'
import LoadingBox from '../components/LoadingBox'
import { reducer } from '../redux/Reducer'
import { capitalizeFirstLetter } from '../components/DateReverse'

function UpdateStockProducts() {
  const { state } = useContext(Store)
  const { userInfo } = state
  const params = useParams()
  const { id } = params
  const [{ loading, error, loadingUpdate }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [quantity, setQuantity] = useState(0)
  const [category, setCategory] = useState('')
  const [depotId, setDepotId] = useState('')
  const [categories, setCategories] = useState([])

  const categoryChoise = categories.map((category) => ({
    value: `${category.name}`,
    label: `${category.name}`,
  }))

  const transactionNameChoix = [
    { value: 'Stocker', label: 'Stocker' },
    { value: 'Déstocker', label: 'Déstocker' },
  ]

  const { Option } = Select

  const getAllCategories = async () => {
    try {
      const ac = new AbortController()
      const { data } = await axios.get(
        '/api/categories/all',
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        },
        {
          signal: ac.signal,
        }
      )
      dispatch({ type: 'FETCH_SUCCESS' })
      setCategories(data)
    } catch (error) {
      dispatch({ type: 'FETCH_FAIL' })
      console.log(error)
    }
  }

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' })
      await getAllCategories()
    }
    fetchData()
    return () => ac.abort()
  }, [])

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' })
        const { data } = await axios.get(
          `/api/stocks/${id}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          },
          { signal: ac.signal }
        )
        setName(data.name)
        setQuantity(data.quantity)
        setCategory(data.category)
        setDepotId(data.depotId)
        console.log(data)
        dispatch({ type: 'FETCH_SUCCESS', payload: data })
      } catch (error) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: error,
        })
      }
    }
    fetchData()
    return () => ac.abort()
  }, [userInfo, id])

  const onFinish = async () => {
    try {
      if (name === 'Stocker') {
        const checkQuantity = () => {
          if (quantity > 0) {
            return quantity
          } else {
            return -1 * quantity
          }
        }
        dispatch({ type: 'UPDATE_REQUEST' })
        await axios.put(
          `/api/stocks/${id}`,
          {
            name,
            category,
            quantity: checkQuantity(),
          },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        )
        dispatch({ type: 'UPDATE_SUCCESS' })
        message.success('Modification réussie')
        navigate(`/admin/depot/${depotId}`)
      } else {
        dispatch({ type: 'UPDATE_REQUEST' })
        const checkQuantity = () => {
          if (quantity > 0) {
            return -1 * quantity
          } else {
            return quantity
          }
        }
        await axios.put(
          `/api/stocks/${id}`,
          {
            name,
            category,
            quantity: checkQuantity(),
          },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        )
        dispatch({ type: 'UPDATE_SUCCESS' })
        message.success('Modification réussie')
        navigate(`/admin/depot/${depotId}`)
      }
    } catch (error) {
      dispatch({ type: 'UPDATE_FAIL' })
      message.error('Quelque chose ne va pas', error)
    }
  }
  return (
    <div className='authentication'>
      <Row>
        <Col lg={8} xs={22}>
          {loading ? (
            <LoadingBox />
          ) : error ? (
            message.error('Quelque chose ne va pas')
          ) : (
            <>
              <Form layout='vertical' onFinish={onFinish}>
                <h1>
                  <b>MOGES-APP</b>
                </h1>
                <hr />
                <h3>Modifier Stock</h3>

                <Form.Item initialValue={name} name='name' label='Opération'>
                  <Select
                    onChange={(value) => setName(value)}
                    options={transactionNameChoix}
                  ></Select>
                </Form.Item>
                <Form.Item
                  name='quantity'
                  label='Quantité en (Bal)'
                  initialValue={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  initialValue={category}
                  name='category'
                  label='Categorie du produit'
                >
                  <Select
                    onChange={(value) => setCategory(value)}
                    options={categoryChoise}
                  ></Select>
                </Form.Item>
                <div className='d-flex justify-content-between align-items-center'>
                  <Button htmlType='submit' type='primary'>
                    Valider
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default UpdateStockProducts
