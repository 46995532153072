import React, { useContext, useReducer, useState } from 'react'
import { Button, Col, Form, Input, message, Row, Checkbox } from 'antd'
import '../resourses/authentication.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Store } from '../redux/Store'
import { reducer } from '../redux/Reducer'

function AddUser() {
  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })

  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')

  const { state } = useContext(Store)
  const { userInfo } = state

  //Fonction pour creer un nouvel utilisateur
  const onFinish = async () => {
    if (password !== confirmPassword) {
      message.error('password do not match')
      return
    }
    try {
      dispatch({ type: 'FETCH_REQUEST' })
      const { data } = await axios.post(
        '/api/users/new',
        {
          name,
          email,
          isAdmin,
          password,
          confirmPassword,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      )
      dispatch({ type: 'FETCH_SUCCESS' })
      message.success('Inscription réussie')
      navigate('/admin/users')
    } catch (error) {
      dispatch({ type: 'FETCH_FAIL' })
      message.error('Quelque chose ne va pas', error)
    }
  }

  return (
    <div className='authentication'>
      <Row>
        <Col lg={8} xs={22}>
          <Form layout='vertical' onFinish={onFinish}>
            <h1>
              <b>MOGES-APP</b>
            </h1>
            <hr />
            <h3>Ajouter Utilisateur</h3>

            <Form.Item
              name='email'
              label='Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='name'
              label='Nom'
              value={name}
              onChange={(e) => setName(e.target.value)}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name='password'
              label='Mot de passe'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            >
              <Input type='password' />
            </Form.Item>

            <Form.Item
              name='confirmPassword'
              label='Confirmer mot de passe'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            >
              <Input type='password' />
            </Form.Item>

            <Form.Item
              name='checkbox'
              valuePropName='checked'
              label=' Est admin'
              checked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
            >
              <Checkbox></Checkbox>
            </Form.Item>

            <div className='d-flex justify-content-between align-items-center'>
              <Button htmlType='submit' type='primary'>
                Valider
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default AddUser
