import { Card, Col, message, Row, Table } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Store } from '../redux/Store'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import LoadingBox from '../components/LoadingBox'
import { functionReverse, ToLocaleStringFunc } from '../components/DateReverse'
import { reducer } from '../redux/Reducer'
import { useRef } from 'react'

function TransactionByClient() {
  const [{ loading, error, transactions, successDelete }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: '',
    })
  const { state } = useContext(Store)
  const { userInfo } = state
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params

  const [compte, setCompte] = useState([])
  const [dettes, setDettes] = useState([])
  const [remboursements, setRemboursements] = useState([])
  const solde = compte.compte
    ?.map((elt) => (elt._id === id ? elt.totalAmount : 0))
    .reduce((a, b) => a + b, 0)

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`/api/dettes/${id}/dettes`, {
          signal: ac.signal,
        })
        setDettes(data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
    return () => ac.abort()
  }, [id])

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `/api/remboursements/${id}/remboursements`,
          { signal: ac.signal }
        )
        setRemboursements(data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
    return () => ac.abort()
  }, [id])

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `/api/transactions/${id}/transactions`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          },
          { signal: ac.signal }
        )
        dispatch({ type: 'FETCH_TRANSACTIONS_SUCCESS', payload: data })
        setCompte(data)
      } catch (error) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: message.error(error),
        })
      }
    }
    if (successDelete) {
      dispatch({ type: 'DELETE_RESET' })
    } else {
      fetchData()
    }
    return () => ac.abort()
  }, [userInfo, successDelete, id])

  /*const refreshPage = () => {
    window.location.reload()
  }*/

  const deleteHandler = async (transactionId) => {
    try {
      dispatch({ type: 'DELETE_REQUEST' })
      await axios
        .delete(`/api/transactions/${transactionId}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        })
        .then((response) => {
          if (response.data.transactionAmount < 0) {
            const foundDette = dettes.dettes.find(
              (dette) =>
                dette.transactionAmount === response.data.transactionAmount
            )
            if (foundDette) {
              axios
                .delete(`/api/dettes/${foundDette._id}`)
                .catch((err) => console.log(err))
            }
          } else {
            const foundRemboursement = remboursements.remboursements.find(
              (remboursement) =>
                remboursement.transactionAmount ===
                response.data.transactionAmount
            )
            if (foundRemboursement) {
              axios
                .delete(`/api/remboursements/${foundRemboursement._id}`)
                .catch((err) => console.log(err))
            }
          }
        })

      message.success(`Transaction supprimée`)
      dispatch({ type: 'DELETE_SUCCESS' })
      //refreshPage()
    } catch (err) {
      message.error(err)
    }
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (createdAt) => functionReverse(createdAt.substring(0, 10)),
    },
    {
      title: 'Intitulé de la transaction',
      dataIndex: 'name',
    },
    {
      title: 'Moyen de paiement',
      dataIndex: 'paymentMethod',
    },
    {
      title: 'Montant',
      dataIndex: 'transactionAmount',
      render: (transactionAmount) => ToLocaleStringFunc(transactionAmount),
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      render: (id, record) => (
        <div className='d-flex'>
          <EditOutlined
            className='mx-2'
            onClick={() => navigate(`/admin/transaction/${record._id}`)}
          />
          <DeleteOutlined
            className='mx-2'
            onClick={() => deleteHandler(record._id)}
          />
        </div>
      ),
    },
  ]
  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        message.error('Quelque chose ne va pas')
      ) : (
        <>
          <div className='d-flex justify-content-between my-4'>
            <Row gutter={16}>
              <Col flex={4}>
                <Card bordered={true} className='info-dash'>
                  <h4>{ToLocaleStringFunc(solde)} CFA</h4>
                  <p>Solde</p>
                </Card>
              </Col>
            </Row>
          </div>
          <Table
            columns={columns}
            dataSource={transactions}
            rowKey={(item) => item._id}
            bordered
            rowClassName={(record, index) =>
              record.transactionAmount > 0 ? 'green' : 'red'
            }
          />
        </>
      )}
    </>
  )
}

export default TransactionByClient
