import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Button, Col, Form, Input, message, Row, Select } from 'antd'
import '../resourses/authentication.css'
import axios from 'axios'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Store } from '../redux/Store'
import { reducer } from '../redux/Reducer'
import { capitalizeFirstLetter } from '../components/DateReverse'

function AddStockTransaction() {
  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })

  const { state } = useContext(Store)
  const { userInfo, depotInfo } = state

  const { search } = useLocation()
  const redirectInUrl = new URLSearchParams(search).get('redirect')
  const redirect = redirectInUrl
    ? redirectInUrl
    : `/admin/depot/${depotInfo._id}`

  const { Option } = Select

  const transactionNameChoix = ['Stocker', 'Déstocker']

  const [name, setName] = useState('')
  const [quantity, setQuantity] = useState(0)
  const [category, setCategory] = useState('')
  const [categories, setCategories] = useState([])

  const navigate = useNavigate()

  const getAllCategories = async () => {
    try {
      const ac = new AbortController()
      const response = await axios.get(
        '/api/categories/all',
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        },
        {
          signal: ac.signal,
        }
      )
      setCategories(response.data)
      dispatch({ type: 'FETCH_SUCCESS' })
    } catch (error) {
      dispatch({ type: 'FETCH_FAIL' })
      console.log(error)
    }
  }

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' })
      await getAllCategories()
    }
    fetchData()
    return () => ac.abort()
  }, [userInfo])

  const onFinish = async () => {
    if (name === 'Stocker') {
      axios
        .post(
          '/api/stocks/new',
          {
            name,
            category,
            depotId: depotInfo._id,
            quantity,
          },
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        )
        .then((response) => {
          dispatch({ type: 'CREATE_SUCCESS' })
          message.success('Stockage réussi')
          if (response.data) {
            navigate(redirect)
          }
        })
        .catch((error) => {
          return message.error(`Quelque chose ne va pas. ${error}`)
        })
    } else {
      axios
        .post(
          '/api/stocks/new',
          {
            name,
            category,
            depotId: depotInfo._id,
            quantity: -1 * quantity,
          },
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        )
        .then((response) => {
          dispatch({ type: 'CREATE_SUCCESS' })
          message.success('Déstockage réussi')
          if (response.data) {
            navigate(redirect)
          }
        })
        .catch((error) => {
          return message.error(`Quelque chose ne va pas. ${error}`)
        })
    }
  }

  return (
    <div className='authentication'>
      <Row>
        <Col lg={8} xs={22}>
          <Form layout='vertical' onFinish={onFinish}>
            <h1>
              <b>MOGES-APP</b>
            </h1>
            <hr />
            <h3>Gérer Stock</h3>

            <Form.Item name='name' label='Opération'>
              <Select allowClear onChange={(value) => setName(value)}>
                {transactionNameChoix.map((name) => (
                  <Option key={name} value={name}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='quantity'
              label='Quantité en (Bal)'
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            >
              <Input />
            </Form.Item>
            <Form.Item name='category' label='Categorie du produit'>
              <Select allowClear onChange={(value) => setCategory(value)}>
                {categories.map((category, index) => {
                  return (
                    <Option key={index} value={category.name}>
                      {capitalizeFirstLetter(`${category.name}`)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <div className='d-flex justify-content-between align-items-center'>
              <Button htmlType='submit' type='primary'>
                Valider
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default AddStockTransaction
