import React, { useContext, useReducer, useState } from 'react'
import { Button, Col, Form, Input, message, Row, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import '../resourses/authentication.css'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import { Store } from '../redux/Store'
import { reducer } from '../redux/Reducer'

function AddCategories() {
  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })

  const navigate = useNavigate()
  const { search } = useLocation()
  const redirectInUrl = new URLSearchParams(search).get('redirect')
  const redirect = redirectInUrl ? redirectInUrl : '/'

  const [name, setName] = useState('')
  const [image, setImage] = useState('')

  const { state } = useContext(Store)
  const { userInfo } = state

  const onFinish = async () => {
    try {
      dispatch({ type: 'FETCH_REQUEST' })
      await axios.post(
        '/api/categories/new',
        {
          name,
          image,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      )

      dispatch({ type: 'FETCH_SUCCESS' })
      message.success('Catégorie ajoutée')
      navigate(redirect)
    } catch (error) {
      dispatch({ type: 'FETCH_FAIL' })
      message.error('Quelque chose ne va pas', error)
    }
  }

  const props = {
    name: 'file',
    action: '/api/upload',
    headers: {
      authorizaton: `Bearer ${userInfo.token}`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} téléchargée`)
        setImage(info.file.response.secure_url)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  return (
    <div className='authentication'>
      <Row>
        <Col lg={8} xs={22}>
          <Form layout='vertical' onFinish={onFinish}>
            <h1>
              <b>MOGES-APP</b>
            </h1>
            <hr />
            <h3>Ajouter catégorie de produit</h3>

            <Form.Item
              name='name'
              label='Nom'
              value={name}
              onChange={(e) => setName(e.target.value)}
            >
              <Input />
            </Form.Item>

            <Upload listType='picture' {...props}>
              <Button icon={<UploadOutlined />}>Télécharger Image</Button>
            </Upload>
            <br />
            {image ? (
              <Form.Item
                name='image'
                label='Image'
                value={image}
                onChange={(e) => setImage(e.target.value)}
              >
                <Input />
              </Form.Item>
            ) : (
              ''
            )}

            <div className='d-flex justify-content-between align-items-center'>
              <Button htmlType='submit' type='primary'>
                Valider
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default AddCategories
