import { createContext, useReducer } from 'react'

export const Store = createContext()

const initialState = {
  supplierInfo: localStorage.getItem('supplierInfo')
    ? JSON.parse(localStorage.getItem('supplierInfo'))
    : null,
  customerInfo: localStorage.getItem('customerInfo')
    ? JSON.parse(localStorage.getItem('customerInfo'))
    : null,
  depotInfo: localStorage.getItem('depotInfo')
    ? JSON.parse(localStorage.getItem('depotInfo'))
    : null,
  itemStock: localStorage.getItem('itemStock')
    ? JSON.stringify(localStorage.getItem('itemStock'))
    : [],

  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null,

  categoryPage: localStorage.getItem('categoryPage')
    ? localStorage.getItem('categoryPage')
    : 1,

  clientPage: localStorage.getItem('clientPage')
    ? localStorage.getItem('clientPage')
    : 1,

  productPage: localStorage.getItem('productPage')
    ? localStorage.getItem('productPage')
    : 1,
  depotPage: localStorage.getItem('depotPage')
    ? localStorage.getItem('depotPage')
    : 1,
  fournisseurPage: localStorage.getItem('fournisseurPage')
    ? localStorage.getItem('fournisseurPage')
    : 1,

  userPage: localStorage.getItem('userPage')
    ? localStorage.getItem('userPage')
    : 1,

  billsPage: localStorage.getItem('billsPage')
    ? localStorage.getItem('billsPage')
    : 1,

  livraisonPage: localStorage.getItem('livraisonPage')
    ? localStorage.getItem('livraisonPage')
    : 1,

  depensePage: localStorage.getItem('depensePage')
    ? localStorage.getItem('depensePage')
    : 1,

  cart: {
    shippingAddress: localStorage.getItem('shippingAddress')
      ? JSON.parse(localStorage.getItem('shippingAddress'))
      : {},
    paymentMethod: localStorage.getItem('paymentMethod')
      ? localStorage.getItem('paymentMethod')
      : '',
    cartItems: localStorage.getItem('cartItems')
      ? JSON.parse(localStorage.getItem('cartItems'))
      : [],
  },
}
function reducer(state, action) {
  switch (action.type) {
    case 'CART_ADD_ITEM':
      // add to cart
      const newItem = action.payload
      const existItem = state.cart.cartItems.find(
        (item) => item._id === newItem._id
      )
      const cartItems = existItem
        ? state.cart.cartItems.map((item) =>
            item._id === existItem._id ? newItem : item
          )
        : [...state.cart.cartItems, newItem]
      localStorage.setItem('cartItems', JSON.stringify(cartItems))
      return { ...state, cart: { ...state.cart, cartItems } }
    case 'CART_REMOVE_ITEM': {
      const cartItems = state.cart.cartItems.filter(
        (item) => item._id !== action.payload._id
      )
      localStorage.setItem('cartItems', JSON.stringify(cartItems))
      return { ...state, cart: { ...state.cart, cartItems } }
    }
    case 'CART_CLEAR':
      return { ...state, cart: { ...state.cart, cartItems: [] } }

    case 'ADD_ITEM_STOCK':
      return { ...state, itemStock: action.payload }

    case 'CLEAR_ITEM_STOCK':
      return { ...state, itemStock: [] }

    case 'CREATE_CUSTOMER':
      return {
        ...state,
        customerInfo: action.payload,
      }

    case 'CREATE_ DEPOT':
      return {
        ...state,
        depotInfo: action.payload,
      }

    case 'CREATE_SUPPLIER':
      return {
        ...state,
        supplierInfo: action.payload,
      }

    case 'CATEGORY_PAGE':
      return { ...state, categoryPage: action.payload }
    case 'CLIENT_PAGE':
      return { ...state, clientPage: action.payload }

    case 'PRODUCT_PAGE':
      return { ...state, productPage: action.payload }

    case 'DEPOT_PAGE':
      return { ...state, depotPage: action.payload }

    case 'FOURNISSEUR_PAGE':
      return { ...state, fournisseurPage: action.payload }

    case 'USER_PAGE':
      return { ...state, userPage: action.payload }

    case 'BILLS_PAGE':
      return { ...state, billsPage: action.payload }

    case 'LIVRAISON_PAGE':
      return { ...state, livraisonPage: action.payload }

    case 'DEPENSE_PAGE':
      return { ...state, depensePage: action.payload }

    case 'USER_SIGNIN':
      return { ...state, userInfo: action.payload }
    case 'USER_SIGNOUT':
      return {
        ...state,
        userInfo: null,
        cart: {
          cartItems: [],
          shippingAddress: {},
          paymentMethod: '',
        },
      }
    case 'SAVE_SHIPPING_ADDRESS':
      return {
        ...state,
        cart: {
          ...state.cart,
          shippingAddress: action.payload,
        },
      }
    case 'SAVE_PAYMENT_METHOD':
      return {
        ...state,
        cart: { ...state.cart, paymentMethod: action.payload },
      }
    default:
      return state
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = { state, dispatch }
  return <Store.Provider value={value}>{props.children} </Store.Provider>
}
