import React, { useContext, useReducer, useState } from 'react'
import { Button, Col, Form, Input, message, Row, Select } from 'antd'
import '../resourses/authentication.css'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import { Store } from '../redux/Store'
import { reducer } from '../redux/Reducer'

function AddUser() {
  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })

  const { state } = useContext(Store)
  const { userInfo, customerInfo } = state

  const navigate = useNavigate()
  const { search } = useLocation()
  const redirectInUrl = new URLSearchParams(search).get('redirect')
  const redirect = redirectInUrl
    ? redirectInUrl
    : `/admin/customer/${customerInfo._id}`

  const paymentMethodChoix = ['Payé', 'Chèque', 'Carte', 'Dette', 'Virement']

  const [name, setName] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [transactionAmount, setTransactionAmount] = useState(0)

  const { Option } = Select

  const onFinish = async () => {
    if (paymentMethod === 'Dette') {
      axios
        .all([
          axios.post(
            '/api/transactions/new',
            {
              name,
              transactionAmount,
              paymentMethod,
              customerId: customerInfo._id,
            },
            {
              headers: { Authorization: `Bearer ${userInfo.token}` },
            }
          ),
          axios.post(
            '/api/dettes/new',
            {
              name,
              transactionAmount,
              paymentMethod,
              customerId: customerInfo._id,
            },
            {
              headers: { Authorization: `Bearer ${userInfo.token}` },
            }
          ),
        ])
        .then(
          axios.spread((data1, data2) => {
            dispatch({ type: 'CREATE_SUCCESS' })
            message.success('Transaction réussie')
            if (data1) {
              navigate(redirect)
            }

            if (data2) {
              return
            }
          })
        )
        .catch(() => {
          return message.error('Quelque chose ne va pas')
        })
    } else {
      dispatch({ type: 'CREATE_REQUEST' })
      axios
        .all([
          axios.post(
            '/api/transactions/new',
            {
              name,
              transactionAmount,
              paymentMethod,
              customerId: customerInfo._id,
            },
            {
              headers: { Authorization: `Bearer ${userInfo.token}` },
            }
          ),
          axios.post(
            '/api/remboursements/new',
            {
              name,
              transactionAmount,
              paymentMethod,
              customerId: customerInfo._id,
            },
            {
              headers: { Authorization: `Bearer ${userInfo.token}` },
            }
          ),
        ])
        .then(
          axios.spread((data1, data2) => {
            dispatch({ type: 'CREATE_SUCCESS' })
            message.success('Transaction réussie')
            if (data1) {
              navigate(redirect)
            }

            if (data2) {
              return
            }
          })
        )
        .catch(() => {
          return message.error('Quelque chose ne va pas.')
        })
    }
    /*try {
      dispatch({ type: 'CREATE_REQUEST' })
      await axios.post(
        '/api/transactions/new',
        {
          name,
          transactionAmount,
          paymentMethod,
          customerId: customerInfo._id,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      )
      dispatch({ type: 'CREATE_SUCCESS' })
      message.success('Transaction réussie')
      navigate(redirect)
    } catch (error) {
      dispatch({ type: 'CREATE_FAIL' })
      message.error('Quelque chose ne va pas', error)
    }*/
  }

  return (
    <div className='authentication'>
      <Row>
        <Col lg={8} xs={22}>
          <Form layout='vertical' onFinish={onFinish}>
            <h1>
              <b>MOGES-APP</b>
            </h1>
            <hr />
            <h3>Ajouter Transaction</h3>

            <Form.Item
              name='name'
              label='Inititulé'
              value={name}
              onChange={(e) => setName(e.target.value)}
            >
              <Input />
            </Form.Item>
            <Form.Item name='paymentMethod' label='Méthode de paiement'>
              <Select allowClear onChange={(value) => setPaymentMethod(value)}>
                {paymentMethodChoix.map((method) => (
                  <Option key={method} value={method}>
                    {method}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name='transactionAmount'
              label='Montant de la transaction'
              value={transactionAmount}
              onChange={
                paymentMethod === 'Dette'
                  ? (e) => setTransactionAmount(e.target.value * -1)
                  : (e) => setTransactionAmount(e.target.value)
              }
            >
              <Input />
            </Form.Item>

            <div className='d-flex justify-content-between align-items-center'>
              <Button htmlType='submit' type='primary'>
                Valider
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default AddUser
