import React, { useContext, useEffect, useReducer, useState } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import axios from 'axios'
import { Col, message, Row } from 'antd'
import Item from '../components/Item'
import '../resourses/items.css'
import { Store } from '../redux/Store'
import LoadingBox from '../components/LoadingBox'
import { useLocation, useNavigate } from 'react-router-dom'
import { reducer } from '../redux/Reducer'

function Homepage() {
  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })

  const navigate = useNavigate()
  const { search } = useLocation()
  const redirectInUrl = new URLSearchParams(search).get('redirect')
  const redirect = redirectInUrl ? redirectInUrl : '/login'

  const { state } = useContext(Store)
  const { userInfo } = state
  const [itemsData, setItemsData] = useState([])
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')

  useEffect(() => {
    const ac = new AbortController()
    const getAllItems = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' })
        const response = await axios.get('/api/items/get-all-items', {
          signal: ac.signal,
        })
        dispatch({ type: 'FETCH_SUCCESS' })
        setItemsData(response.data)
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL' })
        message.error(error)
      }
    }
    getAllItems()
    return () => ac.abort()
  }, [])

  useEffect(() => {
    const ac = new AbortController()
    const getAllCategories = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' })
        const response = await axios.get(
          '/api/categories/all',
          {
            headers: { Authorization: `Bearer ${userInfo?.token}` },
          },
          { signal: ac.signal }
        )
        dispatch({ type: 'FETCH_SUCCESS' })
        setCategories(response.data)
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL' })
        console.log(error)
      }
    }
    getAllCategories()
    return () => ac.abort()
  }, [userInfo?.token])

  useEffect(() => {
    const ac = new AbortController()
    if (!userInfo) {
      navigate(redirect)
    }
    return () => ac.abort()
  }, [userInfo, redirect, navigate])

  return (
    <DefaultLayout>
      <div className='d-flex categories'>
        {categories.map((category) => (
          <div
            key={category._id}
            onClick={() => setSelectedCategory(category.name)}
            className={`d-flex category ${
              selectedCategory === category.name && 'selected-category'
            }`}
          >
            <h4>{category.name}</h4>
            {category.image ? (
              <img src={category.image} height='60' width='80' alt='' />
            ) : (
              ''
            )}
          </div>
        ))}
      </div>

      {loading ? (
        <LoadingBox />
      ) : error ? (
        message.error('Quelque chose ne va pas')
      ) : (
        <>
          <Row
            gutter={20}
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '40px',
            }}
          ></Row>
          <Row gutter={20}>
            {itemsData
              .filter((i) => i.category === selectedCategory)
              .map((i) => {
                return (
                  <Col xs={24} lg={6} md={12} sm={6} key={i._id}>
                    <Item item={i} />
                  </Col>
                )
              })}
          </Row>
        </>
      )}
    </DefaultLayout>
  )
}

export default Homepage
