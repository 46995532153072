import { Card, Col, message, Row, Table } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Store } from '../redux/Store'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import LoadingBox from '../components/LoadingBox'
import { functionReverse, ToLocaleStringFunc } from '../components/DateReverse'
import { reducer } from '../redux/Reducer'

function GererStockageDepot() {
  const [{ loading, error, stockTransactions, successDelete }, dispatch] =
    useReducer(reducer, {
      loading: false,
      error: '',
    })
  const { state } = useContext(Store)
  const { userInfo } = state
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params

  const [compte, setCompte] = useState([])

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `/api/stocks/${id}/products`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          },
          {
            signal: ac.signal,
          }
        )
        dispatch({ type: 'FETCH_STOCK_TRANSACTIONS_SUCCESS', payload: data })
        setCompte(data)
      } catch (error) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: message.error(error),
        })
      }
    }

    if (successDelete) {
      dispatch({ type: 'DELETE_RESET' })
    } else {
      fetchData()
    }
    return () => ac.abort()
  }, [userInfo, successDelete, id])

  const deleteHandler = async (transactionId) => {
    try {
      dispatch({ type: 'DELETE_REQUEST' })
      await axios
        .delete(`/api/stocks/${transactionId}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        })
        .then((response) => {
          message.success(`Transaction supprimée`)
          dispatch({ type: 'DELETE_SUCCESS' })
        })
    } catch (error) {
      message.error(error)
    }
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (createdAt) => functionReverse(createdAt.substring(0, 10)),
    },
    {
      title: 'Intitulé de la transaction',
      dataIndex: 'name',
    },
    {
      title: 'Catégorie',
      dataIndex: 'category',
    },
    {
      title: 'Quantité en (Bal)',
      dataIndex: 'quantity',
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      render: (id, record) => (
        <div className='d-flex'>
          <EditOutlined
            className='mx-2'
            onClick={() => navigate(`/admin/stock-product/${record._id}`)}
          />
          <DeleteOutlined
            className='mx-2'
            onClick={() => deleteHandler(record._id)}
          />
        </div>
      ),
    },
  ]

  return (
    <div>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        message.error('Quelque chose ne va pas')
      ) : (
        <>
          <div className='d-flex justify-content-between my-4'>
            <Row gutter={16}>
              <Col flex={4}>
                <Card bordered={true} className='info-dash'>
                  <h4>
                    {ToLocaleStringFunc(
                      compte.compte
                        ?.map((elt) => (elt._id === id ? elt.totalQuantity : 0))
                        .reduce((a, b) => a + b, 0)
                    )}{' '}
                    Bals
                  </h4>
                  Total de bals
                </Card>
              </Col>
            </Row>
          </div>

          <Table
            columns={columns}
            dataSource={compte?.products}
            rowKey={(item) => item._id}
            bordered
            rowClassName={(record, index) =>
              record.quantity > 0 ? 'green' : 'red'
            }
          />
        </>
      )}
    </div>
  )
}

export default GererStockageDepot
