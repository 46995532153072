import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
} from 'antd'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import axios from 'axios'

import {
  DeleteOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { Store } from '../redux/Store'
import { reducer } from '../redux/Reducer'
import { ToLocaleStringFunc } from '../components/DateReverse'

function CartPage() {
  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })
  const { state, dispatch: ctxDispatch } = useContext(Store)
  const {
    cart: { cartItems },
    userInfo,
  } = state
  const [billChargeModal, setBillChargeModal] = useState(false)
  const [subTotal, setSubTotal] = useState(0)
  const [customers, setCustomers] = useState([])

  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()

  const warning = (warningmessage) => {
    messageApi.open({
      type: 'warning',
      content: warningmessage,
    })
  }
  const errormessage = (errmessage) => {
    messageApi.open({
      type: 'error',
      content: errmessage,
    })
  }

  const success = (successmessage) => {
    messageApi.open({
      type: 'success',
      content: successmessage,
    })
  }

  const paymentMethod = [
    'Cash',
    'Chèque',
    'Carte',
    'Virement',
    'Déduire du compte',
  ]

  const getAllCustomers = () => {
    const ac = new AbortController()
    dispatch({ type: 'FETCH_REQUEST' })
    axios
      .get(
        '/api/customers/get-all-customers',
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        },
        { signal: ac.signal }
      )
      .then((response) => {
        dispatch({ type: 'FETCH_SUCCESS' })
        setCustomers(response.data)
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAIL' })
        console.log(error)
      })
  }

  const updateCartHandlerToPlus = async (item, quantity) => {
    const { data } = await axios.get(`/api/items/${item._id}`)

    if (data.countInStock < quantity) {
      warning('Rupture de stock')
      return
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    })

    await axios.put(
      `/api/items/${item._id}`,
      {
        ...data,
        countInStock: data.countInStock - 1,
      },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    )
  }

  const updateCartHandlerToMinus = async (item, quantity) => {
    const { data } = await axios.get(`/api/items/${item._id}`)
    if (data.countInStock < quantity) {
      warning('Rupture de stock')
      return
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    })

    await axios.put(
      `/api/items/${item._id}`,
      {
        ...data,
        countInStock: data.countInStock + 1,
      },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    )
  }

  const removeItemHandler = async (item) => {
    const countInStockBeforeCart = cartItems.find((x) => x._id === item._id)
    const { data } = await axios.get(`/api/items/${item._id}`)
    await axios.put(
      `/api/items/${item._id}`,
      {
        ...data,
        countInStock: countInStockBeforeCart.countInStock,
      },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    )
    ctxDispatch({ type: 'CART_REMOVE_ITEM', payload: item })
  }

  const columns = [
    {
      title: 'Nom',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Image',
      key: 'image',
      dataIndex: 'image',
      render: (image, record) => (
        <img src={image} alt='' height='60' width='60' />
      ),
    },
    {
      title: 'Prix',
      key: 'price',
      dataIndex: 'price',
    },
    {
      title: 'Quantité',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (id, record) => (
        <div>
          <Button
            className='pb-3 mx-2 my-1'
            shape='circle'
            size='small'
            onClick={() =>
              updateCartHandlerToMinus(record, record.quantity - 100)
            }
          >
            <p className='my-1' style={{ fontSize: '9px', padding: '2px' }}>
              -100
            </p>
          </Button>
          <Button
            className='pb-3 mx-2 my-1'
            shape='circle'
            size='small'
            onClick={() =>
              updateCartHandlerToMinus(record, record.quantity - 10)
            }
          >
            <p className='my-1' style={{ fontSize: '10px' }}>
              -10
            </p>
          </Button>
          <MinusCircleOutlined
            className='mx-3'
            onClick={() =>
              updateCartHandlerToMinus(record, record.quantity - 1)
            }
          />

          <b>{record.quantity}</b>
          <PlusCircleOutlined
            className='mx-3'
            onClick={() => updateCartHandlerToPlus(record, record.quantity + 1)}
          />
          <Button
            className='pb-3 mx-2 my-1'
            shape='circle'
            size='small'
            onClick={() =>
              updateCartHandlerToMinus(record, record.quantity + 10)
            }
          >
            <p className='my-1' style={{ fontSize: '10px' }}>
              +10
            </p>
          </Button>
          <Button
            className='pb-3 mx-2 my-1'
            shape='circle'
            size='small'
            onClick={() =>
              updateCartHandlerToMinus(record, record.quantity + 100)
            }
          >
            <p className='my-1' style={{ fontSize: '9px', padding: '2px' }}>
              +100
            </p>
          </Button>
        </div>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (id, record, quantity) => (
        <DeleteOutlined onClick={() => removeItemHandler(record, quantity)} />
      ),
    },
  ]

  useEffect(() => {
    let temp = 0
    cartItems.forEach((item) => {
      temp = temp + item.price * item.quantity
    })
    setSubTotal(temp)

    return () => {}
  }, [cartItems])

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      getAllCustomers()
    }
    fetchData()
    return () => ac.abort()
  }, [])

  const onFinish = async (values) => {
    ctxDispatch({ type: 'CREATE_CUSTOMER' })
    localStorage.setItem('customerInfo', JSON.stringify(values))

    const reqObject = {
      ...values,
      subTotal,
      cartItems,
      tax: 0, //Number(((subTotal / 100) * 10).toFixed(2)),
      totalAmount: Number(
        subTotal + 0 //Number(((subTotal / 100) * 10).toFixed(2))
      ),
      //exchange: Number(setClientMoney(clientMoney) - subTotal),
      userId: userInfo._id,
    }
    const getCustomerByName = customers.find(
      (x) => x.name === reqObject.customerName
    )

    console.log('reqObject', reqObject)

    const monnaie = reqObject.givenMoney - reqObject.subTotal

    if (monnaie < 0) {
      axios
        .all([
          axios.post(
            '/api/transactions/new',
            {
              name: 'Dette',
              transactionAmount: monnaie,
              paymentMethod: 'Déduire du compte',
              customerId: getCustomerByName._id,
            },
            {
              headers: { Authorization: `Bearer ${userInfo.token}` },
            }
          ),
          axios.post(
            '/api/dettes/new',
            {
              name: 'Dette',
              transactionAmount: monnaie,
              paymentMethod: 'Déduire du compte',
              customerId: getCustomerByName._id,
            },
            {
              headers: { Authorization: `Bearer ${userInfo.token}` },
            }
          ),
        ])
        .then(
          axios.spread((data1, data2) => {
            if (data2 || data1) {
              return success(
                `Dette du client ${reqObject.customerName} enregistrée`
              )
            }
          })
        )
        .catch(() => message.error('Quelque chose ne va pas'))
    }

    if (reqObject.givenMoney === 0 || reqObject.givenMoney === undefined) {
      axios
        .all([
          axios.post(
            '/api/transactions/new',
            {
              name: 'Dette',
              transactionAmount: reqObject.totalAmount * -1,
              paymentMethod: reqObject.paymentMode,
              customerId: getCustomerByName._id,
            },
            {
              headers: { Authorization: `Bearer ${userInfo.token}` },
            }
          ),
          axios.post(
            '/api/dettes/new',
            {
              name: 'Dette',
              transactionAmount: reqObject.totalAmount * -1,
              paymentMethod: reqObject.paymentMode,
              customerId: getCustomerByName._id,
            },
            {
              headers: { Authorization: `Bearer ${userInfo.token}` },
            }
          ),
        ])
        .then(
          axios.spread((data1, data2) => {
            if (data1 || data2) {
              return success(
                `Dette du client ${reqObject.customerName} enregistrée`
              )
            }
          })
        )
        .catch(() => {
          return errormessage('Quelque chose ne va pas')
        })
    }

    if (reqObject.paymentMethod !== 'Déduire du compte') {
      axios.post('/api/bills/charge-bill', reqObject).then(() => {
        ctxDispatch({ type: 'CART_CLEAR' })
        localStorage.removeItem('cartItems')
        navigate('/bills')
      })
    }
  }

  return (
    <DefaultLayout>
      {contextHolder}
      <h3>Panier</h3>
      <Table
        columns={columns}
        rowKey={(record) => record._id}
        dataSource={cartItems}
        bordered
        pagination={false}
      />
      <hr />
      <div className='d-flex justify-content-end flex-column align-items-end'>
        <div className='subtotal'>
          <h3>
            SOUS TOTAL : <b>{ToLocaleStringFunc(subTotal)} CFA</b>
          </h3>
        </div>

        <Button type='primary' onClick={() => setBillChargeModal(true)}>
          CHARGER FACTURE
        </Button>
      </div>

      <Modal
        title='Facture'
        open={billChargeModal}
        footer={false}
        onCancel={() => setBillChargeModal(false)}
      >
        <Form layout='vertical' onFinish={onFinish}>
          <Form.Item name='customerName' label='Client'>
            <Select>
              {customers.map((customer, index) => {
                return (
                  <Select.Option key={index} value={customer.name}>
                    {customer.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item name='paymentMode' label='Mode de Paiement'>
            <Select>
              {paymentMethod.map((method, index) => {
                return (
                  <Select.Option value={method} key={index}>
                    {method}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item name='givenMoney' label='Somme Reçue'>
            <Input />
          </Form.Item>

          <Form.Item name='isPaid' label='Payé' valuePropName='checked'>
            <Checkbox></Checkbox>
          </Form.Item>
          <Form.Item
            name='isDelivered'
            label='Livré au client'
            valuePropName='checked'
          >
            <Checkbox></Checkbox>
          </Form.Item>

          <div className='charge-bill-amount'>
            <h5>
              Sous-Total : <b>{subTotal}</b>
            </h5>
            <h5>
              Taxe : <b>0{/*((subTotal / 100) * 10).toFixed(2)*/}</b>
            </h5>
            <hr />
            <h2>
              Grand Total : <b>{subTotal + 0}</b>
            </h2>
          </div>

          <div className='d-flex justify-content-end'>
            <Button htmlType='submit' type='primary'>
              GÉNÉRER FACTURE
            </Button>
          </div>
        </Form>
      </Modal>
    </DefaultLayout>
  )
}

export default CartPage
