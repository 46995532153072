import 'antd/dist/reset.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Homepage from './pages/Homepage'
import Items from './pages/Items'
import CartPage from './pages/CartPage'
import Register from './pages/Register'
import Login from './pages/Login'
import Clients from './pages/Clients'
import Bills from './pages/Bills'
import ProtectedRoute from './components/ProtectedRoute'
import AdminRoute from './components/AdminRoute'
import AddUser from './pages/AddUser'
import Users from './pages/Users'
import UpdateUser from './pages/UpdateUser'
import AddCategories from './pages/Categories'
import Bilans from './pages/Bilans'
import UpdateCategory from './pages/UpdateCategory'
import CategoriesList from './pages/CategoriesList'
import Suppliers from './pages/Suppliers'
import Fournisseurs from './pages/Fournisseurs'
import AddTransaction from './pages/AddTransaction'
import ClientDetail from './pages/ClientDetail'
import UpdateTransaction from './pages/UpdateTransaction'
import Depenses from './pages/Depenses'
import ExpensesBilan from './pages/ExpensesBilan'
import SupplierDetail from './pages/SupplierDetail'
import AddFactureLivraison from './pages/AddFactureLivraison'
import UpdateFacturebylivraison from './pages/UpdateFacturebylivraison'
import DepotsPage from './pages/DepotsPage'
import DepotDetail from './pages/DepotDetail'
import AddStockTransaction from './pages/AddStockTransaction'
import UpdateStockProducts from './pages/UpdateStockProducts'

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route
            path='/admin/bilans'
            element={
              <AdminRoute>
                <Bilans />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/depots'
            element={
              <AdminRoute>
                <DepotsPage />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/suppliers'
            element={
              <AdminRoute>
                <Fournisseurs />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/deliveries'
            element={
              <AdminRoute>
                <Suppliers />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/expenses'
            element={
              <AdminRoute>
                <Depenses />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/expenses-bilan'
            element={
              <AdminRoute>
                <ExpensesBilan />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/category/:id'
            element={
              <AdminRoute>
                <UpdateCategory />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/user/:id'
            element={
              <AdminRoute>
                <UpdateUser />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/customer/:id'
            element={
              <AdminRoute>
                <ClientDetail />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/depot/:id'
            element={
              <AdminRoute>
                <DepotDetail />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/delivery/:id'
            element={
              <AdminRoute>
                <SupplierDetail />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/category/new'
            element={
              <AdminRoute>
                <AddCategories />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/new-user'
            element={
              <AdminRoute>
                <AddUser />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/users'
            element={
              <AdminRoute>
                <Users />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/categories'
            element={
              <AdminRoute>
                <CategoriesList />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/transaction/new'
            element={
              <AdminRoute>
                <AddTransaction />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/stock-transaction/new'
            element={
              <AdminRoute>
                <AddStockTransaction />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/facturelivraison/new'
            element={
              <AdminRoute>
                <AddFactureLivraison />
              </AdminRoute>
            }
          />

          <Route
            path='/admin/stock-product/:id'
            element={
              <AdminRoute>
                <UpdateStockProducts />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/transaction/:id'
            element={
              <AdminRoute>
                <UpdateTransaction />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/facturelivraison/:id'
            element={
              <AdminRoute>
                <UpdateFacturebylivraison />
              </AdminRoute>
            }
          />
          <Route
            path='/items'
            element={
              <ProtectedRoute>
                <Items />
              </ProtectedRoute>
            }
          />
          <Route
            path='/cart'
            element={
              <ProtectedRoute>
                <CartPage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/bills'
            element={
              <ProtectedRoute>
                <Bills />
              </ProtectedRoute>
            }
          />
          <Route
            path='/customers'
            element={
              <ProtectedRoute>
                {/*<Customers />*/}
                <Clients />
              </ProtectedRoute>
            }
          />
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<Homepage />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
