import { Button, message } from 'antd'
import React, { useContext, useState } from 'react'
import { Store } from '../redux/Store'
import axios from 'axios'

function Item(props) {
  const { item } = props
  const { state, dispatch: ctxDispatch } = useContext(Store)
  const {
    cart: { cartItems },
    userInfo,
  } = state

  const [countStck, setCountStck] = useState(0)
  const existItem = cartItems.find((x) => x._id === item._id)
  const quantity = existItem ? existItem.quantity + 1 : 1

  const addTocart = async (itm) => {
    const { data } = await axios.get(`/api/items/${itm._id}`)
    if (data.countInStock < quantity) {
      message.warning('Rupture de stock')
      return
    }
    const count = existItem ? existItem.countInStock : data.countInStock
    setCountStck(count - quantity)

    ctxDispatch({ type: 'ADD_ITEM_STOCK' })
    localStorage.setItem('itemStock', JSON.stringify(cartItems))
    ctxDispatch({ type: 'CART_ADD_ITEM', payload: { ...itm, quantity } })
    await axios.put(
      `/api/items/${item._id}`,
      {
        ...data,
        countInStock: data.countInStock - 1,
      },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    )
  }
  return (
    <div className='item'>
      <h4 className='name'>{item.name}</h4>
      <div className='d-flex justify-content-center'>
        <img src={item.image} alt='' height='100' width='100' />
      </div>
      <h4 className='price'>
        <b>Prix : </b>
        {item.price} CFA
      </h4>
      <h4 className='quantity'>
        <b>Stock : </b>
        {countStck ? countStck : item.countInStock - quantity + 1}
      </h4>
      <div className='d-flex justify-content-center'>
        <Button onClick={() => addTocart(item)}>Ajouter au panier</Button>
      </div>
    </div>
  )
}

export default Item
