import axios from 'axios'
import { useEffect, useState } from 'react'
import { useContext } from 'react'
import { Store } from '../redux/Store'

export const AccountSolde = (id) => {
  const { state } = useContext(Store)
  const { userInfo } = state
  const [compte, setCompte] = useState([])

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `/api/transactions/${id}/transactions`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          },
          {
            signal: ac.signal,
          }
        )
        setCompte(data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
    return () => ac.abort()
  }, [id, userInfo])

  const soldeClient = compte.compte
    ?.map((elt) => (elt._id === id ? elt.totalAmount : 0))
    .reduce((a, b) => a + b, 0)

  return soldeClient
}
