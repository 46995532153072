import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'

const BackBtn = () => {
  const navigate = useNavigate()
  return (
    <div onClick={() => navigate(-1)} className='backbtn'>
      <LeftOutlined />
    </div>
  )
}

export default BackBtn
