import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import DefaultLayout from '../components/DefaultLayout'
import axios from 'axios'
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Input, message, Space, Table } from 'antd'
import { Store } from '../redux/Store'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LoadingBox from '../components/LoadingBox'
import Highlighter from 'react-highlight-words'
import { functionReverse } from '../components/DateReverse'
import { reducer } from '../redux/Reducer'

function Users() {
  const [{ loading, error, users, successDelete }, dispatch] = useReducer(
    reducer,
    {
      loading: true,
      error: '',
    }
  )

  const { search } = useLocation()
  const sp = new URLSearchParams(search)
  const page = sp.get('page') || 1

  const { state, dispatch: ctxDispatch } = useContext(Store)
  const { userInfo, userPage } = state
  const navigate = useNavigate()

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [current, setCurrent] = useState(userPage)

  const onChange = (page) => {
    setCurrent(page?.current)
    ctxDispatch({ type: 'USER_PAGE', payload: page?.current })
    localStorage.setItem('userPage', page?.current)
  }

  const searchInput = useRef(null)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const refreshPage = () => {
    window.location.reload()
  }

  const deleteHandler = async (userId) => {
    try {
      dispatch({ type: 'DELETE_REQUEST' })
      await axios.delete(`/api/users/${userId}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      })
      dispatch({ type: 'DELETE_SUCCESS' })
      refreshPage()
      message.success('Utilisateur supprimé')
    } catch (err) {
      message.error('Quelque chose ne va pas')
    }
  }

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ...getColumnSearchProps('email'),
      key: 'email',
    },
    {
      title: 'Est admin',
      dataIndex: 'isAdmin',
      key: 'isAdmin',
      render: (isAdmin) => (isAdmin === true ? 'Oui' : 'Non'),
    },
    {
      title: 'Créé le',
      dataIndex: 'createdAt',
      ...getColumnSearchProps('createdAt'),
      key: 'createdAt',
      render: (createdAt) => functionReverse(createdAt.substring(0, 10)),
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      key: '_id',
      render: (_id) => (
        <div className='d-flex'>
          <EditOutlined
            className='mx-2'
            onClick={() => navigate(`/admin/user/${_id}`)}
          />
          <DeleteOutlined className='mx-2' onClick={() => deleteHandler(_id)} />
          {loading && <LoadingBox />}
        </div>
      ),
    },
  ]

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `/api/users/all?page=${page}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          },
          {
            signal: ac.signal,
          }
        )
        dispatch({ type: 'FETCH_USERS_SUCCESS', payload: data })
        if (data) {
        }
      } catch (error) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: error,
        })
      }
    }
    if (successDelete) {
      dispatch({ type: 'DELETE_RESET' })
    } else {
      fetchData()
    }
    return () => ac.abort()
  }, [page, userInfo, successDelete])

  return (
    <DefaultLayout>
      <div className='d-flex justify-content-between'>
        <h3>Utilisateurs</h3>
        <Button type='primary'>
          <Link to='/admin/new-user'>Ajouter</Link>
        </Button>
      </div>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        message.error('Quelque chose ne va pas')
      ) : (
        <Table
          columns={columns}
          dataSource={users}
          rowKey={(user) => user._id}
          onChange={onChange}
          pagination={{
            defaultCurrent: parseInt(current),
          }}
          bordered
        />
      )}
    </DefaultLayout>
  )
}

export default Users
