import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Input, message, Row } from 'antd'
import '../resourses/authentication.css'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import { Store } from '../redux/Store'

function Login() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const redirectInUrl = new URLSearchParams(search).get('redirect')
  const redirect = redirectInUrl ? redirectInUrl : '/'

  const [name, setName] = useState('')
  const [password, setPassword] = useState('')

  const { state, dispatch: ctxDispatch } = useContext(Store)
  const { userInfo } = state

  const onFinish = async () => {
    try {
      const { data } = await axios.post('/api/users/login', {
        name,
        password,
      })
      ctxDispatch({ type: 'USER_SIGNIN', payload: data })
      localStorage.setItem('userInfo', JSON.stringify(data))
      message.success(
        'Connexion réussie, Veuillez patienter pour la vérification'
      )
      navigate(redirect || '/')
    } catch (error) {
      message.error('Quelque chose ne va pas', error)
    }
  }

  useEffect(() => {
    const ac = new AbortController()
    if (userInfo) {
      navigate(redirect)
    }
    return () => ac.abort()
  }, [redirect, userInfo, navigate])

  return (
    <div className='authentication'>
      <Row>
        <Col lg={8} xs={22}>
          <Form layout='vertical' onFinish={onFinish}>
            <h1>
              <b>MOGES-APP</b>
            </h1>
            <hr />
            <h3>Connexion</h3>

            <Form.Item
              name='name'
              label='Nom'
              value={name}
              onChange={(e) => setName(e.target.value)}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='password'
              label='Mot de passe'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            >
              <Input type='password' />
            </Form.Item>

            <div className='d-flex justify-content-between align-items-center'>
              <Button htmlType='submit' type='primary'>
                Valider
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default Login
