export const functionReverse = (str) => {
  var splitString = str.split('-')
  var reverseArray = splitString.reverse()
  var joinArray = reverseArray.join('-')
  return joinArray
}

export const ToLocaleStringFunc = (num) => {
  if (isNaN(num)) {
    return 0
  }
  return Number(num).toLocaleString('fr-FR')
}

export const PhoneNumberSplit = (num) => {
  if (num[0] === '+') {
    let ind = num.substring(0, 4)
    let phoneNum = num
      .substring(4, num.length)
      .replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')
    let NumSplit = ind + ' ' + phoneNum
    return NumSplit
  }
  var phone = num.replace(
    /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
    '$1 $2 $3 $4 $5'
  )
  return phone
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
