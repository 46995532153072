import { Button, message } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import DefaultLayout from '../components/DefaultLayout'
import LoadingBox from '../components/LoadingBox'
import { Store } from '../redux/Store'
import TransactionByClient from './TransactionByClient'
import { reducer } from '../redux/Reducer'

import { PhoneNumberSplit } from '../components/DateReverse'
import BackBtn from '../components/BackBtn'

function ClientDetail() {
  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })

  const { state, dispatch: ctxDispatch } = useContext(Store)
  const { userInfo } = state
  const params = useParams()
  const { id } = params
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [contact, setContact] = useState('')

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' })
        const { data } = await axios.get(
          `/api/customers/${id}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          },
          { signal: ac.signal }
        )
        setName(data.name)
        setContact(data.contact)
        dispatch({ type: 'FETCH_SUCCESS' })
        ctxDispatch({ type: 'CREATE_CUSTOMER', payload: data })
        localStorage.setItem('customerInfo', JSON.stringify(data))
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: message.error(err),
        })
      }
    }
    fetchData()
    return () => ac.abort()
  }, [userInfo, id, ctxDispatch])

  return (
    <DefaultLayout>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        message.error('Quelque chose ne va pas')
      ) : (
        <>
          <div className='d-flex justify-content-between'>
            <BackBtn />
            <Button
              type='primary'
              onClick={() => navigate('/admin/transaction/new')}
            >
              Ajouter transaction
            </Button>
          </div>
          <div className='d-flex justify-content-between'>
            <h3>Client {name} </h3>
          </div>
          <p style={{ fontSize: '20px' }}>Tél: {PhoneNumberSplit(contact)}</p>
        </>
      )}
      <TransactionByClient />
    </DefaultLayout>
  )
}

export default ClientDetail
