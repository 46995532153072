import axios from 'axios'
import { useEffect, useState } from 'react'

export const GetBilanDettes = () => {
  const [detailsDettes, setDetailsDettes] = useState([])
  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/dettes/details', {
          signal: ac.signal,
        })
        setDetailsDettes(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
    return () => ac.abort()
  }, [])
  return detailsDettes
}
