import { Card, Col, message, Row } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useReducer } from 'react'
import { ToLocaleStringFunc } from '../components/DateReverse'
import LoadingBox from '../components/LoadingBox'
import { reducer } from '../redux/Reducer'
import { Store } from '../redux/Store'

const BilanRemboursement = () => {
  const [{ loading, details, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  })

  const totalDette = details?.compte
    ?.map((elt) => (elt.totalAmount < 0 ? elt.totalAmount : 0))
    .reduce((a, b) => a + b, 0)

  const totalVentes = details?.orders?.[0]?.totalSales

  const cashEnCaisse = totalVentes + totalDette

  const [messageApi, contextHolder] = message.useMessage()

  const errormessage = (errmessage) => {
    messageApi.open({
      type: 'error',
      content: errmessage,
    })
  }

  const { state } = useContext(Store)
  const { userInfo } = state

  useEffect(() => {
    const ac = new AbortController()
    const fetchData = async () => {
      try {
        const { data } = await axios.get('/api/remboursements/details', {
          signal: ac.signal,
        })
        dispatch({ type: 'FETCH_SUCCESS_DETAILS', payload: data })
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: errormessage(err),
        })
      }
    }
    fetchData()
    return () => {
      ac.abort()
    }
  }, [userInfo])
  return (
    <div>
      <h3>Remboursements</h3>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        errormessage('Quelque chose ne va pas')
      ) : (
        <>
          <Row gutter={16}>
            <Col flex={4}>
              <Card bordered={true} className='info-dash-encaisse'>
                <h4>{ToLocaleStringFunc(cashEnCaisse)} CFA</h4>
                <p>Total en caisse</p>
              </Card>
            </Col>
            <Col flex={4}>
              <Card bordered={true} className='info-dash-encaisse'>
                <h4>
                  {ToLocaleStringFunc(
                    details?.remboursementCurrentMonthPreview?.[0]
                      ?.yesterday?.[0]?.totalRemboursements
                  )}{' '}
                  CFA
                </h4>
                <p>Hier</p>
              </Card>
            </Col>
            <Col flex={4}>
              <Card bordered={true} className='info-dash-encaisse'>
                <h4>
                  {ToLocaleStringFunc(
                    details?.remboursementCurrentMonthPreview?.[0]?.today?.[0]
                      ?.totalRemboursements
                  )}{' '}
                  CFA
                </h4>

                <p>Aujourd'hui</p>
              </Card>
            </Col>

            <Col flex={4}>
              <Card bordered={true} className='info-dash-encaisse'>
                <h4>
                  {ToLocaleStringFunc(
                    details?.remboursementCurrentMonthPreview?.[0]?.month?.[0]
                      ?.totalRemboursements
                  )}{' '}
                  CFA
                </h4>
                <p>Ce mois-ci</p>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  )
}

export default BilanRemboursement
